import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBJkf0zH9Xw3L6TW2-9Nji_uGB7tRilTsw",
  authDomain: "afterlife-c47e4.firebaseapp.com",
  projectId: "afterlife-c47e4",
  storageBucket: "afterlife-c47e4.appspot.com",
  messagingSenderId: "814893906959",
  appId: "1:814893906959:web:174950a19f152d0ead777a",
  measurementId: "G-HQDV55H6R5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const ANALYTICS = getAnalytics(app);

export const LOG = (event: string) => {
  logEvent(ANALYTICS, event);
};

export const EVENTS = {
  ARRANGE_CLICKED: "ARRANGE_CLICKED",
  ARRANGE_ERROR: "ARRANGE_ERROR",
  ARRANGE_FAILED: "ARRANGE_FAILED",
  ARRANGE_SUCCESS: "ARRANGE_SUCCESS",
  EVOLVE_CLICKED: "EVOLVE_CLICKED",
  EVOLVE_ERROR: "EVOLVE_ERROR",
  EVOLVE_FAILED: "EVOLVE_FAILED",
  EVOLVE_SUCCESS: "EVOLVE_SUCCESS",
  ICON_SELECTED_OPENSEA: "ICON_SELECTED_OPENSEA",
  ICON_SELECTED_INSTAGRAM: "ICON_SELECTED_INSTAGRAM",
  ICON_SELECTED_TWITTER: "ICON_SELECTED_TWITTER",
  LAB_SUBTAB_CLICKED_EVOLUTION: "LAB_SUBTAB_CLICKED_EVOLUTION",
  LAB_SUBTAB_CLICKED_FESTIVAL: "LAB_SUBTAB_CLICKED_FESTIVAL",
  MINT_CLICKED: "MINT_CLICKED",
  MINT_CLICKED_NO_ACTIVE_MINT: "MINT_CLICKED_NO_ACTIVE_MINT",
  MINT_CLICKED_NOT_CONNECTED: "MINT_CLICKED_NOT_CONNECTED",
  MINT_CLICKED_PRE_MINT: "MINT_CLICKED_PRE_MINT",
  MINT_CLICKED_WRONG_NETWORK: "MINT_CLICKED_WRONG_NETWORK",
  MINT_COUNT_BETTA_ONE: "MINT_COUNT_BETTA_ONE",
  MINT_COUNT_BETTA_TWO: "MINT_COUNT_BETTA_TWO",
  MINT_COUNT_BETTA_THREE: "MINT_COUNT_BETTA_THREE",
  MINT_COUNT_BETTA_FOUR: "MINT_COUNT_BETTA_FOUR",
  MINT_COUNT_BETTA_FIVE: "MINT_COUNT_BETTA_FIVE",
  MINT_COUNT_BETTA_SIX_OR_MORE: "MINT_COUNT_BETTA_SIX_OR_MORE",
  MINT_COUNT_FLOWER_ONE: "MINT_COUNT_FLOWER_ONE",
  MINT_COUNT_FLOWER_TWO: "MINT_COUNT_FLOWER_TWO",
  MINT_COUNT_FLOWER_THREE: "MINT_COUNT_FLOWER_THREE",
  MINT_COUNT_FLOWER_FOUR: "MINT_COUNT_FLOWER_FOUR",
  MINT_COUNT_FLOWER_FIVE: "MINT_COUNT_FLOWER_FIVE",
  MINT_COUNT_FLOWER_SIX_OR_MORE: "MINT_COUNT_FLOWER_SIX_OR_MORE",
  MINT_COUNT_MOONLIGHT_ONE: "MINT_COUNT_MOONLIGHT_ONE",
  MINT_COUNT_MOONLIGHT_TWO: "MINT_COUNT_MOONLIGHT_TWO",
  MINT_COUNT_MOONLIGHT_THREE: "MINT_COUNT_MOONLIGHT_THREE",
  MINT_COUNT_MOONLIGHT_FOUR: "MINT_COUNT_MOONLIGHT_FOUR",
  MINT_COUNT_MOONLIGHT_FIVE: "MINT_COUNT_MOONLIGHT_FIVE",
  MINT_COUNT_MOONLIGHT_SIX_OR_MORE: "MINT_COUNT_MOONLIGHT_SIX_OR_MORE",
  MINT_ERROR_BETTA: "MINT_ERROR_BETTA",
  MINT_ERROR_FLOWER: "MINT_ERROR_FLOWER",
  MINT_ERROR_MOONLIGHT: "MINT_ERROR_MOONLIGHT",
  MINT_SUCCESS_BETTA: "MINT_SUCCESS_BETTA",
  MINT_SUCCESS_FLOWER: "MINT_SUCCESS_FLOWER",
  MINT_SUCCESS_MOONLIGHT: "MINT_SUCCESS_MOONLIGHT",
  NON_ETH_BROWSER: "NON_ETH_BROWSER",
  REMEMBER_ME_BALANCE_ZERO: "REMEMBER_ME_BALANCE_ZERO",
  REMEMBER_ME_BALANCE_ONE: "REMEMBER_ME_BALANCE_ONE",
  REMEMBER_ME_BALANCE_MORE: "REMEMBER_ME_BALANCEMOREO",
  TAB_SELECTED_MINT: "TAB_SELECTED_MINT",
  TAB_SELECTED_GARDEN: "TAB_SELECTED_GARDEN",
  TAB_SELECTED_LAB: "TAB_SELECTED_LAB",
  TAB_SELECTED_MEMORY: "TAB_SELECTED_MEMORY",
  WALLET_CONNECTED: "WALLET_CONNECTED",
};
