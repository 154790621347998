import React, { Component } from "react";
import "./App.css";
import { Nav, Navbar } from "react-bootstrap";
import opensea from "../resources/opensea.png";
import instagram from "../resources/instagram.png";
import twitter from "../resources/twitter.png";

const ICON_SIZE = 20;

export const TAB = {
  MINT: 0,
  GARDEN: 1,
  LAB: 2,
  REMEMBER_ME: 3,
};

type Props = {|
  account: String,
  connectToWallet: () => Promise<void>,
  showingTab: number,
  onClickMint: () => void,
  onClickGarden: () => void,
  onClickLab: () => void,
  onClickOpenSea: () => void,
  onClickInstagram: () => void,
  onClickTwitter: () => void,
  width: number,
|};

class WFNavBar extends Component<Props> {
  render() {
    const isCollapsed = this.props.width < 768;
    return (
      <Navbar
        fixed="top"
        expand="md"
        variant="dark"
        style={{
          backgroundColor: "#000000",
          justifyContent: isCollapsed ? "center" : "space-between",
        }}
      >
        <Navbar.Brand
          style={{ color: "#dadada", marginLeft: 12, marginRight: 30 }}
        >
          Rebirth
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ width: 56, position: "absolute", right: 12, top: 12 }}
        />
        <Navbar.Collapse expand="md">
          <Nav style={{ marginLeft: -12 }} className="me-auto">
            <Nav.Link
              onClick={this.props.onClickMint}
              style={{
                marginTop: 2,
                marginLeft: isCollapsed ? 12 : 0,
                color:
                  this.props.showingTab === TAB.MINT ? "#dadada" : "#ffffff80",
              }}
            >
              MINT
            </Nav.Link>
            <Nav.Link
              onClick={this.props.onClickGarden}
              style={{
                marginTop: 2,
                marginLeft: isCollapsed ? 12 : 0,
                color:
                  this.props.showingTab === TAB.GARDEN
                    ? "#dadada"
                    : "#ffffff80",
              }}
            >
              GARDEN
            </Nav.Link>
            <Nav.Link
              onClick={this.props.onClickLab}
              style={{
                marginTop: 2,
                marginLeft: isCollapsed ? 12 : 0,
                color:
                  this.props.showingTab === TAB.LAB ? "#dadada" : "#ffffff80",
              }}
            >
              LAB
            </Nav.Link>
            <Nav.Link
              onClick={this.props.onClickRememberMe}
              style={{
                marginTop: 2,
                marginLeft: isCollapsed ? 12 : 0,
                color:
                  this.props.showingTab === TAB.REMEMBER_ME
                    ? "#dadada"
                    : "#ffffff80",
              }}
            >
              MEMORY
            </Nav.Link>
            <Navbar.Brand>
              <a
                style={{ marginLeft: 8 }}
                onClick={this.props.onClickOpenSea}
                href="https://opensea.io/lunaikuta?tab=created"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={""}
                  style={{ opacity: 0.8, marginLeft: isCollapsed ? 6 : 8 }}
                  src={opensea}
                  height={ICON_SIZE}
                />
              </a>
            </Navbar.Brand>
            <Navbar.Brand>
              <a
                onClick={this.props.onClickInstagram}
                style={{ marginLeft: isCollapsed ? 14 : 8 }}
                href="https://www.instagram.com/lunaikuta/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={""}
                  style={{ opacity: 0.8 }}
                  src={instagram}
                  height={ICON_SIZE}
                />
              </a>
            </Navbar.Brand>
            <Navbar.Brand>
              <a
                onClick={this.props.onClickTwitter}
                style={{ marginLeft: isCollapsed ? 14 : 8 }}
                href="https://twitter.com/lunaikuta"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={""}
                  src={twitter}
                  style={{ opacity: 0.8 }}
                  height={ICON_SIZE + 1}
                />
              </a>
            </Navbar.Brand>
          </Nav>
        </Navbar.Collapse>
        {isCollapsed ? null : this.props.account != null ? (
          <Navbar.Text
            className="justify-content-end"
            style={{ color: "#dadada", marginRight: 12, marginLeft: 12 }}
          >
            {this.props.width > 780 ? this.props.account : ""}
            {/* {this.props.width > 1384 ? this.props.account : ""} TODO*/}
          </Navbar.Text>
        ) : (
          <Nav.Link
            style={{
              color: "#dadada",
              marginRight: -24,
              marginLeft: 12,
              width: 210,
            }}
            onClick={this.props.connectToWallet}
          >
            {this.props.width > 1170 ? "CONNECT" : ""}
          </Nav.Link>
        )}
      </Navbar>
    );
  }
}
export default WFNavBar;
