import React, { Component } from "react";
import "./App.css";
import { Button } from "react-bootstrap";
import WFDropDown from "./WFDropDown";
import bettaIMG from "../resources/betta.png";
import moonlightIMG from "../resources/moonlight.png";
import rococoParrot from "../resources/rococoParrot.png";
import janVanNess from "../resources/janVanNess.png";
import jonquires from "../resources/jonquires.png";
import laBelleEpoch from "../resources/laBelleEpoch.png";
import mondial from "../resources/mondial.png";
import triumph from "../resources/triumph.png";
import rococoParrotEclipsed from "../resources/rococoParrotEclipsed.png";
import janVanNessEclipsed from "../resources/janVanNessEclipsed.png";
import jonquiresEclipsed from "../resources/jonquiresEclipsed.png";
import triumphEclipsed from "../resources/triumphEclipsed.png";
import laBelleEpochEclipsed from "../resources/laBelleEpochEclipsed.png";
import mondialEclipsed from "../resources/mondialEclipsed.png";
import rococoParrotAlpha from "../resources/rococoParrotAlpha.png";
import janVanNessAlpha from "../resources/janVanNessAlpha.png";
import jonquiresAlpha from "../resources/jonquiresAlpha.png";
import triumphAlpha from "../resources/triumphAlpha.png";
import laBelleEpochAlpha from "../resources/laBelleEpochAlpha.png";
import mondialAlpha from "../resources/mondialAlpha.png";
import darumaLogo from "../resources/darumaLogo.png";
import { CONSTANTS } from "../constants/CONSTANTS";
import brackIMG from "../resources/brackIMG.png";

const THUMBNAIL_ASPECT_RATIO = 2 / 3;
const FUCK_WIDTH_PERCENTAGE = "65%";

type Props = {|
  revealOneHappened: boolean,
  revealTwoHappened: boolean,
  revealThreeHappened: boolean,
  revealFourHappened: boolean,
  hasUnusedGoldBouquet: boolean,
  itemOneTitle: String,
  itemTwoTitle: String,
  onItemOneSelected: (eventKey: string) => void,
  onItemTwoSelected: (eventKey: string) => void,
  height: number,
  tokenCounts: Object,
  onFuckSelected: () => void,
  width: number,
|};

type PairProps = {|
  content: String,
  releaseDate?: ?String,
|};

function Pair(props: PairProps) {
  return (
    <div
      style={{
        textAlign: "center",
        color: props.releaseDate != null ? "red" : "#dadada",
        marginBottom: 30,
        opacity: 0.6,
        fontSize: props.releaseDate != null ? 22 : 16,
      }}
    >
      {props.releaseDate != null ? props.releaseDate : props.content}
    </div>
  );
}

class EvolutionScreen extends Component<Props> {
  constructor(props: Props) {
    super(props);
    const counts = props.tokenCounts;
    const itemOneStrings = [];
    if (
      counts.moonlight > 1 &&
      counts.goldBouquet > 0 &&
      this.props.hasUnusedGoldBouquet
    ) {
      itemOneStrings.push("Moonlight (" + counts.moonlight + "x)");
    }
    if (counts.rococoParrot > 0) {
      itemOneStrings.push("Rococo Parrot (" + counts.rococoParrot + "x)");
    }
    if (counts.mondial > 0) {
      itemOneStrings.push("Mondial (" + counts.mondial + "x)");
    }
    if (counts.janVanNess > 0) {
      itemOneStrings.push("Jan Van Ness (" + counts.janVanNess + "x)");
    }
    if (counts.laBelleEpoch > 0) {
      itemOneStrings.push("La Belle Epoch (" + counts.laBelleEpoch + "x)");
    }
    if (counts.jonquires > 0) {
      itemOneStrings.push("Jonquires (" + counts.jonquires + "x)");
    }
    if (counts.triumph > 0) {
      itemOneStrings.push("Triumph (" + counts.triumph + "x)");
    }
    if (counts.rococoParrotAlpha > 0) {
      itemOneStrings.push(
        "Rococo Parrot Alpha (" + counts.rococoParrotAlpha + "x)"
      );
    }
    if (counts.mondialAlpha > 0) {
      itemOneStrings.push("Mondial Alpha (" + counts.mondialAlpha + "x)");
    }
    if (counts.janVanNessAlpha > 0) {
      itemOneStrings.push(
        "Jan Van Ness Alpha (" + counts.janVanNessAlpha + "x)"
      );
    }
    if (counts.laBelleEpochAlpha > 0) {
      itemOneStrings.push(
        "La Belle Epoch Alpha (" + counts.laBelleEpochAlpha + "x)"
      );
    }
    if (counts.jonquiresAlpha > 0) {
      itemOneStrings.push("Jonquires Alpha (" + counts.jonquiresAlpha + "x)");
    }
    if (counts.triumphAlpha > 0) {
      itemOneStrings.push("Triumph Alpha (" + counts.triumphAlpha + "x)");
    }
    if (counts.rococoParrotEclipsed > 0) {
      itemOneStrings.push(
        "Eclipsed Rococo Parrot (" + counts.rococoParrotEclipsed + "x)"
      );
    }
    if (counts.mondialEclipsed > 0) {
      itemOneStrings.push("Eclipsed Mondial (" + counts.mondialEclipsed + "x)");
    }
    if (counts.janVanNessEclipsed > 0) {
      itemOneStrings.push(
        "Eclipsed Jan Van Ness (" + counts.janVanNessEclipsed + "x)"
      );
    }
    if (counts.laBelleEpochEclipsed > 0) {
      itemOneStrings.push(
        "Eclipsed La Belle Epoch (" + counts.laBelleEpochEclipsed + "x)"
      );
    }
    if (counts.jonquiresEclipsed > 0) {
      itemOneStrings.push(
        "Eclipsed Jonquires (" + counts.jonquiresEclipsed + "x)"
      );
    }
    if (counts.triumphEclipsed > 0) {
      itemOneStrings.push("Triumph Eclipsed (" + counts.triumphEclipsed + "x)");
    }

    const itemTwoStrings = [];

    if (counts.betta > 0) {
      if (
        !this.props.itemOneTitle.includes("Alpha") &&
        !this.props.itemOneTitle.includes("Moonlight")
      ) {
        itemTwoStrings.push("Betta (" + counts.betta + "x)");
      }
    }
    if (counts.moonlight > 0) {
      if (!this.props.itemOneTitle.includes("Eclipsed")) {
        itemTwoStrings.push("Moonlight (" + counts.moonlight + "x)");
      }
    }

    this.state = {
      itemOneStrings,
      itemTwoStrings,
    };
  }

  _getSourceForItemOne = () => {
    if (this.props.itemOneTitle.includes("Moonlight")) {
      return moonlightIMG;
    }
    if (this.props.itemOneTitle.includes("Rococo")) {
      if (this.props.itemOneTitle.includes("Eclipsed")) {
        return rococoParrotEclipsed;
      }
      if (this.props.itemOneTitle.includes("Alpha")) {
        return rococoParrotAlpha;
      }
      return rococoParrot;
    }
    if (this.props.itemOneTitle.includes("Mondial")) {
      if (this.props.itemOneTitle.includes("Eclipsed")) {
        return mondialEclipsed;
      }
      if (this.props.itemOneTitle.includes("Alpha")) {
        return mondialAlpha;
      }
      return mondial;
    }
    if (this.props.itemOneTitle.includes("Jan Van Ness")) {
      if (this.props.itemOneTitle.includes("Eclipsed")) {
        return janVanNessEclipsed;
      }
      if (this.props.itemOneTitle.includes("Alpha")) {
        return janVanNessAlpha;
      }
      return janVanNess;
    }
    if (this.props.itemOneTitle.includes("La Belle Epoch")) {
      if (this.props.itemOneTitle.includes("Eclipsed")) {
        return laBelleEpochEclipsed;
      }
      if (this.props.itemOneTitle.includes("Alpha")) {
        return laBelleEpochAlpha;
      }
      return laBelleEpoch;
    }
    if (this.props.itemOneTitle.includes("Jonquires")) {
      if (this.props.itemOneTitle.includes("Eclipsed")) {
        return jonquiresEclipsed;
      }
      if (this.props.itemOneTitle.includes("Alpha")) {
        return jonquiresAlpha;
      }
      return jonquires;
    }
    if (this.props.itemOneTitle.includes("Triumph")) {
      if (this.props.itemOneTitle.includes("Eclipsed")) {
        return triumphEclipsed;
      }
      if (this.props.itemOneTitle.includes("Alpha")) {
        return triumphAlpha;
      }
      return triumph;
    }

    return brackIMG;
  };

  componentDidMount() {
    if (this.state.itemTwoStrings.length > 0) {
      this.props.onItemTwoSelected(this.state.itemTwoStrings[0]);
    }
    if (this.state.itemOneStrings.length > 0) {
      this.props.onItemOneSelected(this.state.itemOneStrings[0]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemOneTitle !== prevProps.itemOneTitle) {
      const counts = this.props.tokenCounts;
      const itemTwoStrings = [];
      if (counts.moonlight > 0) {
        if (!this.props.itemOneTitle.includes("Eclipsed")) {
          itemTwoStrings.push("Moonlight (" + counts.moonlight + "x)");
        }
      }
      if (counts.betta > 0) {
        if (
          !this.props.itemOneTitle.includes("Alpha") &&
          !this.props.itemOneTitle.includes("Moonlight")
        ) {
          itemTwoStrings.push("Betta (" + counts.betta + "x)");
        }
      }
      this.setState({
        itemTwoStrings,
      });
    }
  }

  render() {
    const FUCK_ITEM_WIDTH =
      this.props.width > 679 ? 202 : (202 / 680) * this.props.width;
    const DROPDOWN_WIDTH =
      this.props.width > 679 ? 220 : (220 / 680) * this.props.width;
    const DROPDOWN_HEIGHT =
      this.props.width > 679 ? 86 : (86 / 680) * this.props.width;
    const DROPDOWN_FONT =
      this.props.width > 679 ? 16 : (16 / 680) * this.props.width;
    const PAIRS_SHOWING = this.props.width >= 1236;
    const FUCK_VIEW_HEIGHT = PAIRS_SHOWING
      ? 580
      : (580 / 968) * this.props.height;
    const PADDING_TOP = (100 / 968) * this.props.height;

    const revealTwoDate = this.props.revealTwoHappened
      ? undefined
      : CONSTANTS.REVEAL_TWO_DATE;
    const revealThreeDate = this.props.revealThreeHappened
      ? undefined
      : CONSTANTS.REVEAL_THREE_DATE;
    const revealFourDate = this.props.revealFourHappened
      ? undefined
      : CONSTANTS.REVEAL_FOUR_DATE;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
          paddingTop: PADDING_TOP,
        }}
      >
        {PAIRS_SHOWING && (
          <div
            style={{
              display: "flex",
              height: "100%",
              overflow: "hidden",
              paddingLeft: 100,
              width: "40%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderStyle: "solid",
                borderWidth: "thin",
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                borderColor: "#dadada",
                flexGrow: 1,
                height: 580,
                maxWidth: 400,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  marginTop: 66,
                  textAlign: "center",
                  color: "#dadada",
                  marginBottom: revealThreeDate ? 149.5 : 82,
                  opacity: 0.6,
                  fontSize: 28,
                }}
              >
                PAIRS
              </div>
              <Pair content={"Tulip + Betta"} releaseDate={revealThreeDate} />
              {!revealThreeDate && (
                <Pair
                  content={"Tulip + Moonlight"}
                  releaseDate={revealFourDate}
                />
              )}
              {!revealThreeDate && (
                <Pair
                  content={"Alpha Tulip + Moonlight"}
                  releaseDate={revealFourDate}
                />
              )}
              {!revealThreeDate && (
                <Pair
                  content={"Eclipsed Tulip + Betta"}
                  releaseDate={revealFourDate}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                  maxWidth: 400,
                  width: this.props.width > 1399 ? 400 : undefined,
                }}
              >
                <img
                  alt={""}
                  src={darumaLogo}
                  style={{
                    marginTop: revealThreeDate ? 149.5 : 64,
                    opacity: 0.6,
                    aspectRatio: 57 / 109,
                    width: 60,
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            height: FUCK_VIEW_HEIGHT,
            width: PAIRS_SHOWING ? "60%" : "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",

              width: FUCK_WIDTH_PERCENTAGE,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: 150,
                marginBottom: 100,
              }}
            >
              <WFDropDown
                onSelect={this.props.onItemOneSelected}
                itemStrings={this.state.itemOneStrings}
                itemTitle={this.props.itemOneTitle}
                toggleStyle={{
                  fontSize: DROPDOWN_FONT,
                  width: DROPDOWN_WIDTH,
                  height: DROPDOWN_HEIGHT,
                }}
              />
              <img
                alt={""}
                src={this._getSourceForItemOne()}
                style={{
                  aspectRatio: THUMBNAIL_ASPECT_RATIO,
                  width: FUCK_ITEM_WIDTH,
                  marginTop: 50,
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                alignSelf: "flex-start",
                textAlign: "center",
                color: "#dadada",
                opacity: 0.6,
                fontSize: 50,
                height: DROPDOWN_HEIGHT,
                marginTop: 0,
              }}
            >
              +
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: 150,
                marginBottom: 100,
              }}
            >
              <WFDropDown
                onSelect={this.props.onItemTwoSelected}
                itemStrings={this.state.itemTwoStrings}
                itemTitle={this.props.itemTwoTitle}
                toggleStyle={{
                  fontSize: DROPDOWN_FONT,
                  width: DROPDOWN_WIDTH,
                  height: DROPDOWN_HEIGHT,
                }}
              />
              <img
                alt={""}
                src={
                  this.props.itemTwoTitle.includes("Moonlight")
                    ? moonlightIMG
                    : this.props.itemTwoTitle.includes("Betta")
                    ? bettaIMG
                    : brackIMG
                }
                style={{
                  aspectRatio: THUMBNAIL_ASPECT_RATIO,
                  width: FUCK_ITEM_WIDTH,
                  marginTop: 50,
                  objectFit: "contain",
                }}
              ></img>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: FUCK_WIDTH_PERCENTAGE,
            }}
          >
            <Button
              onClick={this.props.onFuckSelected}
              style={{
                opacity: 1,
                height: 90.8,
                width: 269.51,
                fontSize: "2.2rem",
              }}
              variant="outline-light"
            >
              evolve
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default EvolutionScreen;
