import React, { Component } from "react";
import "./App.css";
import bettaIMG from "../resources/betta.png";
import moonlightIMG from "../resources/moonlight.png";
import rococoParrot from "../resources/rococoParrot.png";
import janVanNess from "../resources/janVanNess.png";
import jonquires from "../resources/jonquires.png";
import laBelleEpoch from "../resources/laBelleEpoch.png";
import mondial from "../resources/mondial.png";
import triumph from "../resources/triumph.png";
import triumphVid from "../resources/triumphVid.mp4";
import triumphAlpha from "../resources/triumphAlpha.png";
import triumphAlphaVid from "../resources/triumphAlphaVid.mp4";
import triumphEclipsed from "../resources/triumphEclipsed.png";
import triumphEclipsedVid from "../resources/triumphEclipsedVid.mp4";
import triumphAlphaEclipsed from "../resources/triumphAlphaEclipsed.png";
import triumphAlphaEclipsedVid from "../resources/triumphAlphaEclipsedVid.mp4";
import rococoParrotEclipsed from "../resources/rococoParrotEclipsed.png";
import janVanNessEclipsed from "../resources/janVanNessEclipsed.png";
import jonquiresEclipsed from "../resources/jonquiresEclipsed.png";
import rococoParrotEclipsedVid from "../resources/rococoParrotEclipsedVid.mp4";
import janVanNessEclipsedVid from "../resources/janVanNessEclipsedVid.mp4";
import jonquiresEclipsedVid from "../resources/jonquiresEclipsedVid.mp4";
import laBelleEpochEclipsed from "../resources/laBelleEpochEclipsed.png";
import laBelleEpochEclipsedVid from "../resources/laBelleEpochEclipsedVid.mp4";
import mondialEclipsed from "../resources/mondialEclipsed.png";
import mondialEclipsedVid from "../resources/mondialEclipsedVid.mp4";
import rococoParrotAlpha from "../resources/rococoParrotAlpha.png";
import janVanNessAlpha from "../resources/janVanNessAlpha.png";
import jonquiresAlpha from "../resources/jonquiresAlpha.png";
import laBelleEpochAlpha from "../resources/laBelleEpochAlpha.png";
import mondialAlpha from "../resources/mondialAlpha.png";
import rococoParrotAlphaEclipsed from "../resources/rococoParrotAlphaEclipsed.png";
import janVanNessAlphaEclipsed from "../resources/janVanNessAlphaEclipsed.png";
import jonquiresAlphaEclipsed from "../resources/jonquiresAlphaEclipsed.png";
import laBelleEpochAlphaEclipsed from "../resources/laBelleEpochAlphaEclipsed.png";
import mondialAlphaEclipsed from "../resources/mondialAlphaEclipsed.png";
import rococoParrotAlphaEclipsedVid from "../resources/rococoParrotAlphaEclipsedVid.mp4";
import janVanNessAlphaEclipsedVid from "../resources/janVanNessAlphaEclipsedVid.mp4";
import jonquiresAlphaEclipsedVid from "../resources/jonquiresAlphaEclipsedVid.mp4";
import laBelleEpochAlphaEclipsedVid from "../resources/laBelleEpochAlphaEclipsedVid.mp4";
import mondialAlphaEclipsedVid from "../resources/mondialAlphaEclipsedVid.mp4";
import vignette from "../resources/vignette.png";
import rococoParrotVid from "../resources/rococoParrotVid.mp4";
import janVanNessVid from "../resources/janVanNessVid.mp4";
import jonquiresVid from "../resources/jonquiresVid.mp4";
import laBelleEpochVid from "../resources/laBelleEpochVid.mp4";
import mondialVid from "../resources/mondialVid.mp4";
import rococoParrotAlphaVid from "../resources/rococoParrotAlphaVid.mp4";
import janVanNessAlphaVid from "../resources/janVanNessAlphaVid.mp4";
import jonquiresAlphaVid from "../resources/jonquiresAlphaVid.mp4";
import laBelleEpochAlphaVid from "../resources/laBelleEpochAlphaVid.mp4";
import mondialAlphaVid from "../resources/mondialAlphaVid.mp4";
import rebirth from "../resources/rebirth.png";
import rebirthVid from "../resources/rebirthVid.mp4";
import moonlightVid from "../resources/moonlightVid.mp4";
import bettaVid from "../resources/bettaVid.mp4";
import bronzeTalisman from "../resources/bronzeTalisman.png";
import silverTalisman from "../resources/silverTalisman.png";
import goldTalisman from "../resources/goldTalisman.png";
import stoneTalisman from "../resources/stoneTalisman.png";
import platinumTalisman from "../resources/platinumTalisman.png";
import spiritTalisman from "../resources/spiritTalisman.png";
import bronzeTalismanVid from "../resources/bronzeTalismanVid.mp4";
import silverTalismanVid from "../resources/silverTalismanVid.mp4";
import goldTalismanVid from "../resources/goldTalismanVid.mp4";
import stoneTalismanVid from "../resources/stoneTalismanVid.mp4";
import platinumTalismanVid from "../resources/platinumTalismanVid.mp4";
import spiritTalismanVid from "../resources/spiritTalismanVid.mp4";
import darumaLogoWhite from "../resources/darumaLogoWhite.png";
import bronzeBouquetOne from "../resources/bronzeBouquetOne.png";
import bronzeBouquetTwo from "../resources/bronzeBouquetTwo.png";
import bronzeBouquetThree from "../resources/bronzeBouquetThree.png";
import silverBouquetOne from "../resources/silverBouquetOne.png";
import silverBouquetTwo from "../resources/silverBouquetTwo.png";
import silverBouquetThree from "../resources/silverBouquetThree.png";
import goldBouquet from "../resources/goldBouquet.png";
import platinumBouquet from "../resources/platinumBouquet.png";
import bronzeBouquetOneVid from "../resources/bronzeBouquetOneVid.mp4";
import bronzeBouquetTwoVid from "../resources/bronzeBouquetTwoVid.mp4";
import bronzeBouquetThreeVid from "../resources/bronzeBouquetThreeVid.mp4";
import silverBouquetOneVid from "../resources/silverBouquetOneVid.mp4";
import silverBouquetTwoVid from "../resources/silverBouquetTwoVid.mp4";
import silverBouquetThreeVid from "../resources/silverBouquetThreeVid.mp4";
import goldBouquetVid from "../resources/goldBouquetVid.mp4";
import platinumBouquetVid from "../resources/platinumBouquetVid.mp4";
import { CONSTANTS } from "../constants/CONSTANTS";
import { NAMES } from "../constants/URIS";
import LockedDateScreen from "./LockedDateScreen";

type Token = {|
  name: String,
  description: String,
  animation_url: String,
  image: String,
|};

type Props = {|
  revealOneHappened: boolean,
  revealTwoHappened: boolean,
  revealThreeHappened: boolean,
  revealFourHappened: boolean,
  hasUnusedGoldBouquet: boolean,
  height: number,
  width: number,
  tokenCounts: Object,
|};

const PREVIEW_WIDTH_PERCENTAGE = "45%";
const GRID_WIDTH_PERCENTAGE = "55%";
const THUMBNAIL_ASPECT_RATIO = 2 / 3;
const ARROW_MARGIN_BOTTOM = 31;

const GARDEN_CATEGORY = {
  ESSENTIALS: 0,
  TULIPS: 1,
  TRIUMPH: 2,
  BOUQUETS: 3,
  TALISMANS: 4,
};

type GardenInfo = {|
  revealTwoHappened?: ?boolean,
  srcVideo: String,
  srcImage: String,
  count: number,
  name: String,
  subtitle?: ?String,
  releaseDate?: ?String,
|};

type GardenRowProps = {|
  onGridItemSelected: (GardenInfo) => void,
  sourceArray: Array<GardenInfo>,
  heightBase?: number,
  heightMultiplier: number,
|};

function GardenRow(props: GardenRowProps) {
  const heightMultiplier = props.heightMultiplier;
  let heightBase = props.heightBase != null ? props.heightBase : 180;
  let height = heightMultiplier * heightBase;
  let titleFontSize = heightMultiplier * 14;
  const length = props.sourceArray.length;
  if (length === 5) {
    heightBase = props.heightBase != null ? props.heightBase : 130;
    titleFontSize = heightMultiplier * 10;
    height = heightMultiplier * heightBase;
  } else if (length === 3) {
    heightBase = props.heightBase != null ? props.heightBase : 210;
    titleFontSize = heightMultiplier * 16;
    height = heightMultiplier * heightBase;
  } else if (length <= 2) {
    heightBase = props.heightBase != null ? props.heightBase : 280;
    height = heightMultiplier * heightBase;
    titleFontSize = heightMultiplier * 20;
  }
  return (
    <div
      style={{
        display: "flex",
        height: height,
        width: "100%",
        justifyContent: "center",
        overflow: "hidden",
        flexDirection: "row",
        marginBottom: 24,
      }}
    >
      {props.sourceArray.map((gardenInfo, index) => {
        const gardenInfoView =
          gardenInfo.count > 0 ||
          (props.revealTwoHappened && gardenInfo.name === NAMES.REBIRTH) ? (
            <img
              alt={""}
              src={gardenInfo.srcImage}
              style={{
                width: height * THUMBNAIL_ASPECT_RATIO,
                height: height,
              }}
            />
          ) : (
            <div
              style={{
                color: "#dadada",
                fontSize: titleFontSize,
                textAlign: "center",
                marginRight: 10,
                marginLeft: 10,
              }}
            >
              {gardenInfo.name}
            </div>
          );
        return (
          <div
            onClick={() => {
              if (!gardenInfo.releaseDate) {
                props.onGridItemSelected(gardenInfo);
              }
            }}
            style={{
              cursor: !gardenInfo.releaseDate ? "pointer" : undefined,
              width: height * THUMBNAIL_ASPECT_RATIO,
              height: height,
              overflow: "hidden",
              marginLeft: index !== 0 ? props.heightMultiplier * 12 : 0,
              marginRight:
                index !== props.sourceArray.length - 1
                  ? props.heightMultiplier * 12
                  : 0,
            }}
          >
            <div
              style={{
                display: "flex",
                borderStyle: "solid",
                borderWidth: "thin",
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                borderColor: "#dadada",
                width: height * THUMBNAIL_ASPECT_RATIO,
                height: height,
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              {gardenInfo.releaseDate && (
                <div
                  style={{
                    color: "red",
                    fontSize: titleFontSize,
                    textAlign: "center",
                  }}
                >
                  {gardenInfo.releaseDate}
                </div>
              )}
              {!gardenInfo.releaseDate && gardenInfoView}
              {!gardenInfo.releaseDate && gardenInfo.count > 0 && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 4,
                    color: "#dadada",
                    fontSize: "0.9rem",
                    textAlign: "center",
                  }}
                >
                  {gardenInfo.count + "x"}
                </div>
              )}
            </div>
            {!gardenInfo.releaseDate && (
              <div
                class="cover"
                style={{
                  backgroundColor: "#dadada",
                  width: height * THUMBNAIL_ASPECT_RATIO,
                  height: height,
                  position: "absolute",
                  objectFit: "contain",
                  borderBottomRightRadius: 8,
                  borderBottomLeftRadius: 8,
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

class GardenScreen extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeGardenCategory: GARDEN_CATEGORY.ESSENTIALS,
      contentClass: "faderContent",
      previewClass: "fader",
      previewName: "",
      previewSrc: null,
      queuedGardenCategory: null,
      queuedPreviewSrc: null,
      queuedPreviewName: null,
    };
  }

  _getTitleForGardenCategory = () => {
    const category = this.state.activeGardenCategory;
    let title = "ESSENTIALS";
    if (category === GARDEN_CATEGORY.TULIPS) {
      title = this.props.revealTwoHappened ? "TULIPS" : "⚘ ⚘ ⚘ ⚘ ⚘";
    }
    if (category === GARDEN_CATEGORY.TRIUMPH) {
      title = "SECRET GARDEN";
    }
    if (category === GARDEN_CATEGORY.BOUQUETS) {
      title = "BOUQUETS";
    }
    if (category === GARDEN_CATEGORY.TALISMANS) {
      title = "";
    }
    return title;
  };

  _nextCategory = () => {
    const counts = this.props.tokenCounts;
    const hasTriumph =
      counts.triumph > 0 ||
      counts.triumphEclipsed > 0 ||
      counts.triumphAlpha > 0 ||
      counts.triumphAlphaEclipsed > 0;
    // console.log("triumphCount: ", counts.triumph);
    let nextCat = GARDEN_CATEGORY.ESSENTIALS;
    if (this.state.activeGardenCategory !== GARDEN_CATEGORY.TALISMANS) {
      if (
        this.state.activeGardenCategory === GARDEN_CATEGORY.TULIPS &&
        !(hasTriumph || this.props.hasUnusedGoldBouquet)
      ) {
        nextCat = GARDEN_CATEGORY.BOUQUETS;
      } else {
        nextCat = this.state.activeGardenCategory + 1;
      }
    }

    this.setState({ queuedGardenCategory: nextCat });
  };

  _previousCategory = () => {
    const counts = this.props.tokenCounts;
    const hasTriumph =
      counts.triumph > 0 ||
      counts.triumphEclipsed > 0 ||
      counts.triumphAlpha > 0 ||
      counts.triumphAlphaEclipsed > 0;
    let prevCat = GARDEN_CATEGORY.TALISMANS;
    if (this.state.activeGardenCategory !== GARDEN_CATEGORY.ESSENTIALS) {
      if (
        this.state.activeGardenCategory === GARDEN_CATEGORY.BOUQUETS &&
        !(hasTriumph || this.props.hasUnusedGoldBouquet)
      ) {
        prevCat = GARDEN_CATEGORY.TULIPS;
      } else {
        prevCat = this.state.activeGardenCategory - 1;
      }
    }
    this.setState({ queuedGardenCategory: prevCat });
  };

  _onGridItemSelected = (item: GardenInfo) => {
    this.setState({ queuedPreviewName: item.name });
    if (
      item.count > 0 ||
      (item.name === NAMES.REBIRTH && this.props.revealTwoHappened)
    ) {
      this.setState({ queuedPreviewSrc: item.srcVideo });
    } else {
      this.setState({ queuedPreviewSrc: null });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.queuedPreviewSrc !== prevState.queuedPreviewSrc &&
        this.state.queuedPreviewSrc != null) ||
      (this.state.queuedPreviewName !== prevState.queuedPreviewName &&
        this.state.queuedPreviewName != null)
    ) {
      this.setState({
        previewClass: "faderTwo",
      });
      setTimeout(
        () => {
          this.setState({
            previewSrc: this.state.queuedPreviewSrc,
            previewName: this.state.queuedPreviewName,
            queuedPreviewSrc: null,
            queuedPreviewName: null,
            previewClass: "fader",
          });
        },
        this.state.previewName === "" ? 0 : 500
      );
    }

    if (
      this.state.queuedGardenCategory !== prevState.queuedGardenCategory &&
      this.state.queuedGardenCategory != null
    ) {
      this.setState({
        contentClass: "faderContentTwo",
      });
      setTimeout(() => {
        this.setState(
          {
            activeGardenCategory: this.state.queuedGardenCategory,
            queuedGardenCategory: null,
          },
          () => {
            setTimeout(() => {
              this.setState({
                contentClass: "faderContent",
              });
            }, 50);
          }
        );
      }, 150);
    }
  }

  render() {
    return <LockedDateScreen lockedDate={"COMING SOON"} />;
    const PREVIEW_SHOWING = this.props.width >= 1254;
    const PADDING_TOP_MAX = (130 / 968) * this.props.height;
    const PADDING_TOP = PADDING_TOP_MAX > 108 ? PADDING_TOP_MAX : 108;

    const heightMultiplierByWidth =
      this.props.width < 760
        ? (this.props.width - (760 - this.props.width) / 18) / 760
        : 1;
    const heightLimit =
      this.state.activeGardenCategory === GARDEN_CATEGORY.TULIPS
        ? 968
        : this.state.activeGardenCategory === GARDEN_CATEGORY.ESSENTIALS ||
          this.state.activeGardenCategory === GARDEN_CATEGORY.TRIUMPH
        ? 550
        : this.state.activeGardenCategory === GARDEN_CATEGORY.BOUQUETS
        ? 750
        : this.state.activeGardenCategory === GARDEN_CATEGORY.TALISMANS
        ? 880
        : 880;
    const heightMultiplierByHeight =
      this.props.height < heightLimit
        ? (this.props.height - (heightLimit - this.props.height) / 18) /
          heightLimit
        : 1;
    const GRID_ITEM_HEIGHT_MULTIPLIER =
      heightMultiplierByHeight > heightMultiplierByWidth
        ? heightMultiplierByWidth
        : heightMultiplierByHeight;
    const counts = this.props.tokenCounts;
    const revealOneDate = this.props.revealOneHappened
      ? null
      : CONSTANTS.REVEAL_ONE_DATE;
    const revealTwoDate = this.props.revealTwoHappened
      ? null
      : CONSTANTS.REVEAL_TWO_DATE;
    const revealThreeDate = this.props.revealThreeHappened
      ? null
      : CONSTANTS.REVEAL_THREE_DATE;
    const revealFourDate = this.props.revealFourHappened
      ? null
      : CONSTANTS.REVEAL_FOUR_DATE;

    const ESSENTIALS_CONTENT = (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          revealTwoHappened={this.props.revealTwoHappened}
          sourceArray={[
            {
              srcVideo: rebirthVid,
              srcImage: rebirth,
              count: counts.rebirth,
              name: NAMES.REBIRTH,
              releaseDate: revealOneDate,
            },
            {
              srcVideo: bettaVid,
              srcImage: bettaIMG,
              count: counts.betta,
              name: NAMES.BETTA,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: moonlightVid,
              srcImage: moonlightIMG,
              count: counts.moonlight,
              name: NAMES.MOONLIGHT,
              releaseDate: revealFourDate,
            },
          ]}
        />
      </div>
    );

    // console.log("counts: ", counts);

    const TULIPS_CONTENT = (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: jonquiresVid,
              srcImage: jonquires,
              count: counts.jonquires,
              name: NAMES.FLOWER_FIVE,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: laBelleEpochVid,
              srcImage: laBelleEpoch,
              count: counts.laBelleEpoch,
              name: NAMES.FLOWER_FOUR,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: janVanNessVid,
              srcImage: janVanNess,
              count: counts.janVanNess,
              name: NAMES.FLOWER_THREE,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: mondialVid,
              srcImage: mondial,
              count: counts.mondial,
              name: NAMES.FLOWER_TWO,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: rococoParrotVid,
              srcImage: rococoParrot,
              count: counts.rococoParrot,
              name: NAMES.FLOWER_ONE,
              releaseDate: revealTwoDate,
            },
          ]}
        />
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: jonquiresAlphaVid,
              srcImage: jonquiresAlpha,
              count: counts.jonquiresAlpha,
              name: NAMES.FLOWER_FIVE_ALPHA,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: laBelleEpochAlphaVid,
              srcImage: laBelleEpochAlpha,
              count: counts.laBelleEpochAlpha,
              name: NAMES.FLOWER_FOUR_ALPHA,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: janVanNessAlphaVid,
              srcImage: janVanNessAlpha,
              count: counts.janVanNessAlpha,
              name: NAMES.FLOWER_THREE_ALPHA,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: mondialAlphaVid,
              srcImage: mondialAlpha,
              count: counts.mondialAlpha,
              name: NAMES.FLOWER_TWO_ALPHA,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: rococoParrotAlphaVid,
              srcImage: rococoParrotAlpha,
              count: counts.rococoParrotAlpha,
              name: NAMES.FLOWER_ONE_ALPHA,
              releaseDate: revealThreeDate,
            },
          ]}
        />
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: jonquiresEclipsedVid,
              srcImage: jonquiresEclipsed,
              count: counts.jonquiresEclipsed,
              name: NAMES.FLOWER_FIVE_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: laBelleEpochEclipsedVid,
              srcImage: laBelleEpochEclipsed,
              count: counts.laBelleEpochEclipsed,
              name: NAMES.FLOWER_FOUR_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: janVanNessEclipsedVid,
              srcImage: janVanNessEclipsed,
              count: counts.janVanNessEclipsed,
              name: NAMES.FLOWER_THREE_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: mondialEclipsedVid,
              srcImage: mondialEclipsed,
              count: counts.mondialEclipsed,
              name: NAMES.FLOWER_TWO_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: rococoParrotEclipsedVid,
              srcImage: rococoParrotEclipsed,
              count: counts.rococoParrotEclipsed,
              name: NAMES.FLOWER_ONE_ECLIPSED,
              releaseDate: revealFourDate,
            },
          ]}
        />

        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: jonquiresAlphaEclipsedVid,
              srcImage: jonquiresAlphaEclipsed,
              count: counts.jonquiresAlphaEclipsed,
              name: NAMES.FLOWER_FIVE_ALPHA_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: laBelleEpochAlphaEclipsedVid,
              srcImage: laBelleEpochAlphaEclipsed,
              count: counts.laBelleEpochAlphaEclipsed,
              name: NAMES.FLOWER_FOUR_ALPHA_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: janVanNessAlphaEclipsedVid,
              srcImage: janVanNessAlphaEclipsed,
              count: counts.janVanNessAlphaEclipsed,
              name: NAMES.FLOWER_THREE_ALPHA_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: mondialAlphaEclipsedVid,
              srcImage: mondialAlphaEclipsed,
              count: counts.mondialAlphaEclipsed,
              name: NAMES.FLOWER_TWO_ALPHA_ECLIPSED,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: rococoParrotAlphaEclipsedVid,
              srcImage: rococoParrotAlphaEclipsed,
              count: counts.rococoParrotAlphaEclipsed,
              name: NAMES.FLOWER_ONE_ALPHA_ECLIPSED,
              releaseDate: revealFourDate,
            },
          ]}
        />
      </div>
    );

    const TRIUMPH_CONTENT = (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: triumphVid,
              srcImage: triumph,
              count: counts.triumph,
              name: "Triumph",
            },
            {
              srcVideo: triumphAlphaVid,
              srcImage: triumphAlpha,
              count: counts.triumphAlpha,
              name: "Triumph Alpha",
            },
            {
              srcVideo: triumphEclipsedVid,
              srcImage: triumphEclipsed,
              count: counts.triumphEclipsed,
              name: "Triumph Eclipsed",
            },
            {
              srcVideo: triumphAlphaEclipsedVid,
              srcImage: triumphAlphaEclipsed,
              count: counts.triumphAlphaEclipsed,
              name: "Eclipsed Triumph Alpha",
            },
          ]}
        />
      </div>
    );

    const BOUQUETS_CONTENT = (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: bronzeBouquetOneVid,
              srcImage: bronzeBouquetOne,
              count: counts.bronzeBouquetOne,
              name: NAMES.BRONZE_BOUQUET_ONE,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: bronzeBouquetTwoVid,
              srcImage: bronzeBouquetTwo,
              count: counts.bronzeBouquetTwo,
              name: NAMES.BRONZE_BOUQUET_TWO,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: bronzeBouquetThreeVid,
              srcImage: bronzeBouquetThree,
              count: counts.bronzeBouquetThree,
              name: NAMES.BRONZE_BOUQUET_THREE,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: goldBouquetVid,
              srcImage: goldBouquet,
              count: counts.goldBouquet,
              name: NAMES.GOLD_BOUQUET,
              releaseDate: revealFourDate,
            },
          ]}
        />
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: silverBouquetOneVid,
              srcImage: silverBouquetOne,
              count: counts.silverBouquetOne,
              name: NAMES.SILVER_BOUQUET_ONE,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: silverBouquetTwoVid,
              srcImage: silverBouquetTwo,
              count: counts.silverBouquetTwo,
              name: NAMES.SILVER_BOUQUET_TWO,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: silverBouquetThreeVid,
              srcImage: silverBouquetThree,
              count: counts.silverBouquetThree,
              name: NAMES.SILVER_BOUQUET_THREE,
              releaseDate: revealThreeDate,
            },

            {
              srcVideo: platinumBouquetVid,
              srcImage: platinumBouquet,
              count: counts.platinumBouquet,
              name: NAMES.PLATINUM_BOUQUET,
              releaseDate: revealFourDate,
            },
          ]}
        />
      </div>
    );

    const TALISMAN_CONTENT = (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: bronzeTalismanVid,
              srcImage: bronzeTalisman,
              count: counts.bronzeTalisman,
              name: NAMES.BRONZE_TALISMAN,
              releaseDate: revealTwoDate,
            },
            {
              srcVideo: silverTalismanVid,
              srcImage: silverTalisman,
              count: counts.silverTalisman,
              name: NAMES.SILVER_TALISMAN,
              releaseDate: revealThreeDate,
            },
            {
              srcVideo: goldTalismanVid,
              srcImage: goldTalisman,
              count: counts.goldTalisman,
              name: NAMES.GOLD_TALISMAN,
              releaseDate: revealFourDate,
            },
          ]}
        />
        <GardenRow
          onGridItemSelected={this._onGridItemSelected}
          heightMultiplier={GRID_ITEM_HEIGHT_MULTIPLIER}
          sourceArray={[
            {
              srcVideo: stoneTalismanVid,
              srcImage: stoneTalisman,
              count: counts.stoneTalisman,
              name: NAMES.STONE_TALISMAN,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: platinumTalismanVid,
              srcImage: platinumTalisman,
              count: counts.platinumTalisman,
              name: NAMES.PLATINUM_TALISMAN,
              releaseDate: revealFourDate,
            },
            {
              srcVideo: spiritTalismanVid,
              srcImage: spiritTalisman,
              count: counts.spiritTalisman,
              name: NAMES.SPIRIT_TALISMAN,
              releaseDate: revealFourDate,
            },
          ]}
        />
      </div>
    );

    const gardenContent =
      this.state.activeGardenCategory === GARDEN_CATEGORY.ESSENTIALS
        ? ESSENTIALS_CONTENT
        : this.state.activeGardenCategory === GARDEN_CATEGORY.TULIPS
        ? TULIPS_CONTENT
        : this.state.activeGardenCategory === GARDEN_CATEGORY.TRIUMPH
        ? TRIUMPH_CONTENT
        : this.state.activeGardenCategory === GARDEN_CATEGORY.BOUQUETS
        ? BOUQUETS_CONTENT
        : TALISMAN_CONTENT;

    return (
      <div
        class="fader"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          height: "100%",
          width: "100%",
          paddingTop: PADDING_TOP,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            width: PREVIEW_SHOWING ? GRID_WIDTH_PERCENTAGE : "100%",
            paddingBottom: 140,
          }}
        >
          {this.state.activeGardenCategory === GARDEN_CATEGORY.TALISMANS ? (
            <img
              alt={""}
              src={darumaLogoWhite}
              style={{
                // marginTop: 60,
                opacity: 0.95,
                height: 60,
                objectFit: "contain",
                paddingBottom: 30,
              }}
            />
          ) : (
            <div
              style={{
                height: 60,
                color: "#dadada",
                fontSize: 20,
                textAlign: "center",
                paddingBottom: 30,
              }}
            >
              {this._getTitleForGardenCategory()}
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              paddingRight:
                this.props.width < 760
                  ? 50 *
                    ((this.props.width - (760 - this.props.width) / 2) / 760)
                  : 50,
              paddingLeft:
                this.props.width < 760
                  ? 50 *
                    ((this.props.width - (760 - this.props.width) / 2) / 760)
                  : 50,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              onClick={this._previousCategory}
              style={{
                cursor: "pointer",
                color: "#dadada",
                fontSize: "3.2rem",
                textAlign: "center",
                marginBottom: ARROW_MARGIN_BOTTOM,
              }}
            >
              {"<"}
            </div>
            <div
              class={this.state.contentClass}
              style={{
                display: "flex",
                height: "100%",
                overflow: "hidden",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {gardenContent}
            </div>
            <div
              onClick={() => {
                this._nextCategory();
              }}
              style={{
                cursor: "pointer",
                color: "#dadada",
                fontSize: "3.2rem",
                textAlign: "center",
                marginBottom: ARROW_MARGIN_BOTTOM,
              }}
            >
              {">"}
            </div>
          </div>
        </div>
        {PREVIEW_SHOWING && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
              width: PREVIEW_WIDTH_PERCENTAGE,
            }}
          >
            <div
              class={this.state.previewClass}
              style={{
                color: "#dadada",
                fontSize: "1.1rem",
                textAlign: "center",
              }}
            >
              {this.state.previewName}
            </div>
            {this.state.previewSrc != null && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  aspectRatio: 0.5625,
                  height: this.props.height - 170,
                  bottom: 0,
                  left: 0,
                }}
              >
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                  src={this.state.previewSrc}
                  class={this.state.previewClass}
                  style={{
                    bottom: 0,
                    height: this.props.height - 170,
                    width: (this.props.height - 170) * 0.5625,
                    objectFit: "contain",
                  }}
                />
                {this.state.previewName != NAMES.REBIRTH && (
                  <img
                    alt={""}
                    src={vignette}
                    // class={this.state.previewClass}
                    style={{
                      display: "flex",
                      bottom: 0,
                      height: this.props.height - 169,
                      position: "absolute",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default GardenScreen;
