import React, { Component } from "react";
import "./App.css";
import { Button } from "react-bootstrap";
import WFDropDown from "./WFDropDown";
import rococoParrot from "../resources/rococoParrot.png";
import janVanNess from "../resources/janVanNess.png";
import jonquires from "../resources/jonquires.png";
import laBelleEpoch from "../resources/laBelleEpoch.png";
import mondial from "../resources/mondial.png";
import rococoParrotEclipsed from "../resources/rococoParrotEclipsed.png";
import janVanNessEclipsed from "../resources/janVanNessEclipsed.png";
import jonquiresEclipsed from "../resources/jonquiresEclipsed.png";
import laBelleEpochEclipsed from "../resources/laBelleEpochEclipsed.png";
import mondialEclipsed from "../resources/mondialEclipsed.png";
import rococoParrotAlpha from "../resources/rococoParrotAlpha.png";
import janVanNessAlpha from "../resources/janVanNessAlpha.png";
import jonquiresAlpha from "../resources/jonquiresAlpha.png";
import laBelleEpochAlpha from "../resources/laBelleEpochAlpha.png";
import mondialAlpha from "../resources/mondialAlpha.png";
import rococoParrotAlphaEclipsed from "../resources/rococoParrotAlphaEclipsed.png";
import janVanNessAlphaEclipsed from "../resources/janVanNessAlphaEclipsed.png";
import jonquiresAlphaEclipsed from "../resources/jonquiresAlphaEclipsed.png";
import laBelleEpochAlphaEclipsed from "../resources/laBelleEpochAlphaEclipsed.png";
import mondialAlphaEclipsed from "../resources/mondialAlphaEclipsed.png";
import darumaLogo from "../resources/darumaLogo.png";
import { CONSTANTS } from "../constants/CONSTANTS";

const THUMBNAIL_ASPECT_RATIO = 2 / 3;
const ARRANGE_WIDTH_PERCENTAGE = "65%";

// type FlowerInfo = {|
//   srcImage: String,
//   count: number,
//   name: String,
//   releaseDate?: ?String,
// |};

type Props = {|
  revealTwoHappened: boolean,
  revealThreeHappened: boolean,
  revealFourHappened: boolean,
  height: number,
  bouquetItemTitle: String,
  onArrangeSelected: () => void,
  onBouquetItemSelected: (eventKey: string) => void,
  height: number,
  tokenCounts: Object,
  width: number,
|};

class BouquetScreen extends Component<Props> {
  _getFlowersInfoForSelectedBouquetType = () => {
    const revealTwoDate = this.props.revealTwoHappened
      ? null
      : CONSTANTS.REVEAL_TWO_DATE;
    const revealThreeDate = this.props.revealThreeHappened
      ? null
      : CONSTANTS.REVEAL_THREE_DATE;
    const revealFourDate = this.props.revealFourHappened
      ? null
      : CONSTANTS.REVEAL_FOUR_DATE;

    const counts = this.props.tokenCounts;
    let flowersInfo = [
      {
        srcImage: jonquires,
        count: counts.jonquires,
        name: "Jonquires",
        releaseDate: revealTwoDate,
      },
      {
        srcImage: laBelleEpoch,
        count: counts.laBelleEpoch,
        name: "La Belle Epoch",
        releaseDate: revealTwoDate,
      },
      {
        srcImage: janVanNess,
        count: counts.janVanNess,
        name: "Jan Van Ness",
        releaseDate: revealTwoDate,
      },
      {
        srcImage: mondial,
        count: counts.mondial,
        name: "Mondial",
        releaseDate: revealTwoDate,
      },
      {
        srcImage: rococoParrot,
        count: counts.rococoParrot,
        name: "Rococo Parrot",
        releaseDate: revealTwoDate,
      },
    ];
    if (this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET) {
      flowersInfo = [
        {
          srcImage: jonquiresAlpha,
          count: counts.jonquiresAlpha,
          name: "Jonquires Alpha",
          releaseDate: revealThreeDate,
        },
        {
          srcImage: laBelleEpochAlpha,
          count: counts.laBelleEpochAlpha,
          name: "La Belle Epoch Alpha",
          releaseDate: revealThreeDate,
        },
        {
          srcImage: janVanNessAlpha,
          count: counts.janVanNessAlpha,
          name: "Jan Van Ness Alpha",
          releaseDate: revealThreeDate,
        },
        {
          srcImage: mondialAlpha,
          count: counts.mondialAlpha,
          name: "Mondial Alpha",
          releaseDate: revealThreeDate,
        },
        {
          srcImage: rococoParrotAlpha,
          count: counts.rococoParrotAlpha,
          name: "Rococo Parrot Alpha",
          releaseDate: revealThreeDate,
        },
      ];
    } else if (this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET) {
      flowersInfo = [
        {
          srcImage: jonquiresEclipsed,
          count: counts.jonquiresEclipsed,
          name: "Jonquires Eclipsed",
          releaseDate: revealFourDate,
        },
        {
          srcImage: laBelleEpochEclipsed,
          count: counts.laBelleEpochEclipsed,
          name: "La Belle Epoch Eclipsed",
          releaseDate: revealFourDate,
        },
        {
          srcImage: janVanNessEclipsed,
          count: counts.janVanNessEclipsed,
          name: "Jan Van Ness Eclipsed",
          releaseDate: revealFourDate,
        },
        {
          srcImage: mondialEclipsed,
          count: counts.mondialEclipsed,
          name: "Mondial Eclipsed",
          releaseDate: revealFourDate,
        },
        {
          srcImage: rococoParrotEclipsed,
          count: counts.rococoParrotEclipsed,
          name: "Rococo Parrot Eclipsed",
          releaseDate: revealFourDate,
        },
      ];
    } else if (this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET) {
      flowersInfo = [
        {
          srcImage: jonquiresAlphaEclipsed,
          count: counts.jonquiresAlphaEclipsed,
          name: "Eclipsed Jonquires Alpha",
          releaseDate: revealFourDate,
        },
        {
          srcImage: laBelleEpochAlphaEclipsed,
          count: counts.laBelleEpochAlphaEclipsed,
          name: "Eclipsed La Belle Epoch Alpha",
          releaseDate: revealFourDate,
        },
        {
          srcImage: janVanNessAlphaEclipsed,
          count: counts.janVanNessAlphaEclipsed,
          name: "Eclipsed Jan Van Ness Alpha",
          releaseDate: revealFourDate,
        },
        {
          srcImage: mondialAlphaEclipsed,
          count: counts.mondialAlphaEclipsed,
          name: "Eclipsed Mondial Alpha",
          releaseDate: revealFourDate,
        },
        {
          srcImage: rococoParrotAlphaEclipsed,
          count: counts.rococoParrotAlphaEclipsed,
          name: "Eclipsed Rococo Parrot Alpha",
          releaseDate: revealFourDate,
        },
      ];
    }
    return flowersInfo;
  };

  render() {
    const BOUQUET_ITEM_WIDTH =
      this.props.width > 1049 ? 160 : (160 / 1050) * this.props.width;
    const BOUQUET_FONT_SIZE =
      this.props.width > 1049 ? 18 : (18 / 1050) * this.props.width;
    const COUNT_FONT_SIZE =
      this.props.width > 1049 ? 16 : (16 / 1050) * this.props.width;
    const COUNT_MARGIN =
      this.props.width > 1049 ? 40 : (40 / 1050) * this.props.width;
    const BOUQUET_ITEM_MARGIN =
      this.props.width > 1049 ? 8 : (7 / 1050) * this.props.width;
    const DROPDOWN_WIDTH =
      this.props.width > 499 ? 280 : (280 / 500) * this.props.width;
    const DROPDOWN_FONT =
      this.props.width > 499 ? 16 : (16 / 500) * this.props.width;
    const PREVIEW_SHOWING = this.props.width >= 1400;
    const ARRANGE_VIEW_HEIGHT = PREVIEW_SHOWING
      ? 580
      : (580 / 968) * this.props.height;
    const PADDING_TOP = (100 / 968) * this.props.height;

    const properRevealHappened =
      this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET ||
      this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? this.props.revealFourHappened
        : this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? this.props.revealThreeHappened
        : this.props.revealTwoHappened;

    const revealDate =
      this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET ||
      this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? CONSTANTS.REVEAL_FOUR_DATE
        : this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? CONSTANTS.REVEAL_THREE_DATE
        : CONSTANTS.REVEAL_TWO_DATE;

    const title =
      this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? "SILVER"
        : this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? "GOLD"
        : this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET
        ? "PLATINUM"
        : "BRONZE";

    const stringOne =
      this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? "Each Eclipsed Tulip"
        : this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? "Each Alpha Tulip"
        : this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET
        ? "Each Eclipsed Alpha Tulip"
        : "Each Tulip";

    const stringTwo =
      this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? "First 20 To Claim"
        : this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? "First 15 To Claim"
        : this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET
        ? "Platinum Bouquet"
        : "First 25 To Claim";

    const stringThree =
      this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? "Summon The Silver Talisman"
        : this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? "Summon The Gold Talisman"
        : this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET
        ? "Summons a Talisman"
        : "Summon The Bronze Talisman";

    const stringFour =
      this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? "Silver Bouquet I - 38%"
        : this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? "Gold Bouquet - 100%"
        : this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET
        ? "Spirit Talisman - 1/1"
        : "Bronze Bouquet I - 50%";

    const stringFive =
      this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? "Silver Bouquet II - 33%"
        : this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? ""
        : this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET
        ? "Platinum Talisman - 1/10"
        : "Bronze Bouquet II - 30%";

    const stringSix =
      this.props.bouquetItemTitle === CONSTANTS.SILVER_BOUQUET
        ? "Silver Bouquet III - 29%"
        : this.props.bouquetItemTitle === CONSTANTS.GOLD_BOUQUET
        ? ""
        : this.props.bouquetItemTitle === CONSTANTS.PLATINUM_BOUQUET
        ? "Stone Talisman - 1/X"
        : "Bronze Bouquet III - 20%";

    const flowersInfo = this._getFlowersInfoForSelectedBouquetType();

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
          paddingTop: PADDING_TOP,
        }}
      >
        {PREVIEW_SHOWING && (
          <div
            style={{
              display: "flex",
              height: "100%",
              overflow: "hidden",
              paddingLeft: 100,
              width: "40%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderStyle: "solid",
                borderWidth: "thin",
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                borderColor: "#dadada",
                flexGrow: 1,
                height: 580,
                maxWidth: 400,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  marginTop: 66,
                  textAlign: "center",
                  color: "#dadada",
                  marginBottom: 64,
                  opacity: 0.6,
                  fontSize: 28,
                }}
              >
                {title}
              </div>
              {properRevealHappened && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#dadada",
                    opacity: 0.6,
                    fontSize: 18,
                    marginRight: 16,
                    marginLeft: 16,
                  }}
                >
                  {"Arrange One of"}
                </div>
              )}
              {properRevealHappened && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#dadada",
                    opacity: 0.6,
                    fontSize: 18,
                    marginRight: 16,
                    marginLeft: 16,
                    marginBottom: 32,
                  }}
                >
                  {stringOne}
                </div>
              )}
              {properRevealHappened && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#dadada",
                    opacity: 0.6,
                    fontSize: 16,
                    marginRight: 16,
                    marginLeft: 16,
                  }}
                >
                  {stringTwo}
                </div>
              )}
              {properRevealHappened && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#dadada",
                    opacity: 0.6,
                    fontSize: 16,
                    marginRight: 16,
                    marginLeft: 16,
                  }}
                >
                  {stringThree}
                </div>
              )}
              {properRevealHappened && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#dadada",
                    marginTop: 32,
                    opacity: 0.6,
                    fontSize: 16,
                    marginRight: 16,
                    marginLeft: 16,
                  }}
                >
                  {stringFour}
                </div>
              )}
              {properRevealHappened && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#dadada",
                    opacity: 0.6,
                    fontSize: 16,
                    marginRight: 16,
                    marginLeft: 16,
                    height: 24,
                  }}
                >
                  {stringFive}
                </div>
              )}
              {properRevealHappened && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#dadada",
                    opacity: 0.6,
                    fontSize: 16,
                    marginRight: 16,
                    marginLeft: 16,
                    height: 24,
                  }}
                >
                  {stringSix}
                </div>
              )}
              {!properRevealHappened && (
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 18,
                    marginTop: 157,
                    marginBottom: 118,
                  }}
                >
                  {revealDate}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <img
                  alt={""}
                  src={darumaLogo}
                  style={{
                    marginTop: 60,
                    opacity: 0.6,
                    aspectRatio: 57 / 109,
                    width: 60,
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            height: ARRANGE_VIEW_HEIGHT,
            width: PREVIEW_SHOWING ? "60%" : "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              width: ARRANGE_WIDTH_PERCENTAGE,
            }}
          >
            {" "}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: PREVIEW_SHOWING
                  ? "space-between"
                  : "flex-start",
                flexDirection: "column",
                marginBottom: 100,
              }}
            >
              <WFDropDown
                onSelect={this.props.onBouquetItemSelected}
                itemStrings={[
                  CONSTANTS.BRONZE_BOUQUET,
                  CONSTANTS.SILVER_BOUQUET,
                  CONSTANTS.GOLD_BOUQUET,
                  CONSTANTS.PLATINUM_BOUQUET,
                ]}
                itemTitle={this.props.bouquetItemTitle}
                toggleStyle={{ fontSize: DROPDOWN_FONT, width: DROPDOWN_WIDTH }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                {flowersInfo.map((flowerInfo) => {
                  // console.log("flowerInfo.count: ", flowerInfo.count);
                  // console.log("flowerInfo.name: ", flowerInfo.name);

                  const flowerInfoContentView =
                    flowerInfo.count > 0 ? (
                      <img
                        alt={""}
                        src={flowerInfo.srcImage}
                        style={{
                          aspectRatio: THUMBNAIL_ASPECT_RATIO,
                          width: BOUQUET_ITEM_WIDTH,
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          aspectRatio: THUMBNAIL_ASPECT_RATIO,
                          width: BOUQUET_ITEM_WIDTH,
                          color: "#dadada",
                          fontSize: BOUQUET_FONT_SIZE,
                          marginRight: 10,
                          marginLeft: 10,
                          textAlign: "center",
                        }}
                      >
                        {flowerInfo.name}
                      </div>
                    );
                  return (
                    <div
                      style={{
                        display: "flex",
                        aspectRatio: THUMBNAIL_ASPECT_RATIO,
                        width: BOUQUET_ITEM_WIDTH,
                        height: BOUQUET_ITEM_WIDTH / THUMBNAIL_ASPECT_RATIO,
                        marginTop: 94,
                        borderColor: "#dadada",
                        borderStyle: "solid",
                        borderWidth: "thin",
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8,
                        overflow: "hidden",
                        marginRight: BOUQUET_ITEM_MARGIN,
                        marginLeft: BOUQUET_ITEM_MARGIN,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {flowerInfo.releaseDate && (
                        <div
                          style={{
                            aspectRatio: THUMBNAIL_ASPECT_RATIO,
                            width: BOUQUET_ITEM_WIDTH,
                            color: "red",
                            fontSize: BOUQUET_FONT_SIZE,
                            marginRight: 10,
                            marginLeft: 10,
                            textAlign: "center",
                          }}
                        >
                          {flowerInfo.releaseDate}
                        </div>
                      )}
                      {!flowerInfo.releaseDate && flowerInfoContentView}
                      {!flowerInfo.releaseDate && flowerInfo.count > 0 && (
                        <div
                          style={{
                            display: "flex",
                            position: "absolute",
                            marginLeft: BOUQUET_ITEM_WIDTH - COUNT_MARGIN,
                            marginTop:
                              2 * (BOUQUET_ITEM_WIDTH * THUMBNAIL_ASPECT_RATIO),
                            color: "#dadada",
                            fontSize: COUNT_FONT_SIZE,
                            textAlign: "end",
                          }}
                        >
                          {flowerInfo.count + "x"}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: ARRANGE_WIDTH_PERCENTAGE,
            }}
          >
            <Button
              onClick={this.props.onArrangeSelected}
              style={{
                opacity: 1,
                height: 90.8,
                width: 269.51,
                fontSize: "2.2rem",
              }}
              variant="outline-light"
            >
              arrange
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default BouquetScreen;
