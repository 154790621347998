import React, { Component } from "react";
import "./App.css";
import { Modal } from "react-bootstrap";

type Props = {|
  body: String,
  showing: Boolean,
  onHide: () => void,
  title: String,
|};

class WFModal extends Component<Props> {
  render() {
    return (
      <Modal
        size="lg"
        show={this.props.showing}
        onHide={this.props.onHide}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.body}</Modal.Body>
      </Modal>
    );
  }
}
export default WFModal;
