export const CONSTANTS = {
  REVEAL_ONE_DATE: "6.13.2022",
  REVEAL_TWO_DATE: "6.14.2022",
  REVEAL_THREE_DATE: "6.15.2022",
  REVEAL_FOUR_DATE: "6.17.2022",
  BRONZE_BOUQUET: "Bronze Bouquet",
  SILVER_BOUQUET: "Silver Bouquet",
  GOLD_BOUQUET: "Gold Bouquet",
  PLATINUM_BOUQUET: "Platinum Bouquet",
};
