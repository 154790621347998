import React, { Component } from "react";
import Web3 from "web3";
import "./App.css";
import ERC721 from "../abis/ERC721.json";
import Rebirth from "../abis/Rebirth.json";
import MintScreen from "./MintScreen";
import WFNavBar, { TAB } from "./WFNavBar";
import ourStory from "../resources/ourStory.mp4";
import LabScreen from "./LabScreen";
import GardenScreen from "./GardenScreen";
import WFModal from "./WFModal";
import transitionBronzeBouquet from "../resources/transitionBronzeBouquet.mp4";
import transitionBronzeBouquetBronzeTalisman from "../resources/transitionBronzeBouquetBronzeTalisman.mp4";
import transitionSilverBouquet from "../resources/transitionSilverBouquet.mp4";
import transitionSilverBouquetSilverTalisman from "../resources/transitionSilverBouquetSilverTalisman.mp4";
import transitionGoldBouquet from "../resources/transitionGoldBouquet.mp4";
import transitionGoldBouquetGoldTalisman from "../resources/transitionGoldBouquetGoldTalisman.mp4";
import transitionPlatinumBouquetStoneTalisman from "../resources/transitionPlatinumBouquetStoneTalisman.mp4";
import transitionPlatinumBouquetSpiritTalisman from "../resources/transitionPlatinumBouquetSpiritTalisman.mp4";
import transitionPlatinumBouquetPlatinumTalisman from "../resources/transitionPlatinumBouquetPlatinumTalisman.mp4";

import transitionRococoParrotEclipsedAlpha from "../resources/transitionRococoParrotEclipsedAlpha.mp4";
import transitionRococoParrotAlphaEclipsed from "../resources/transitionRococoParrotAlphaEclipsed.mp4";
import transitionRococoParrotEclipsed from "../resources/transitionRococoParrotEclipsed.mp4";
import transitionRococoParrotAlpha from "../resources/transitionRococoParrotAlpha.mp4";

import transitionMondialEclipsedAlpha from "../resources/transitionMondialEclipsedAlpha.mp4";
import transitionMondialAlphaEclipsed from "../resources/transitionMondialAlphaEclipsed.mp4";
import transitionMondialEclipsed from "../resources/transitionMondialEclipsed.mp4";
import transitionMondialAlpha from "../resources/transitionMondialAlpha.mp4";

import transitionJanVanNessEclipsedAlpha from "../resources/transitionJanVanNessEclipsedAlpha.mp4";
import transitionJanVanNessAlphaEclipsed from "../resources/transitionJanVanNessAlphaEclipsed.mp4";
import transitionJanVanNessEclipsed from "../resources/transitionJanVanNessEclipsed.mp4";
import transitionJanVanNessAlpha from "../resources/transitionJanVanNessAlpha.mp4";

import transitionLaBelleEpochEclipsedAlpha from "../resources/transitionLaBelleEpochEclipsedAlpha.mp4";
import transitionLaBelleEpochAlphaEclipsed from "../resources/transitionLaBelleEpochAlphaEclipsed.mp4";
import transitionLaBelleEpochEclipsed from "../resources/transitionLaBelleEpochEclipsed.mp4";
import transitionLaBelleEpochAlpha from "../resources/transitionLaBelleEpochAlpha.mp4";

import transitionJonquiresEclipsedAlpha from "../resources/transitionJonquiresEclipsedAlpha.mp4";
import transitionJonquiresAlphaEclipsed from "../resources/transitionJonquiresAlphaEclipsed.mp4";
import transitionJonquiresEclipsed from "../resources/transitionJonquiresEclipsed.mp4";
import transitionJonquiresAlpha from "../resources/transitionJonquiresAlpha.mp4";

import transitionTriumphAlpha from "../resources/transitionTriumphAlpha.mp4";
import transitionTriumphAlphaEclipsed from "../resources/transitionTriumphAlphaEclipsed.mp4";
import transitionTriumphEclipsed from "../resources/transitionTriumphEclipsed.mp4";
import transitionTriumph from "../resources/transitionTriumph.mp4";

import { BASE_URIS, URIS } from "../constants/URIS";
import { CONSTANTS } from "../constants/CONSTANTS";
import { LOG, EVENTS } from "../constants/FIREBASE";
import LockedDateScreen from "./LockedDateScreen";

const REMEMBER_ME_CONTRACT_ADDRESS =
  "0xCCB9D89e0F77Df3618EEC9f6BF899Be3B5561A89";
const EVOLUTION_ITEM_ONE_PLACEHOLDER = "Item 1";
const EVOLUTION_ITEM_TWO_PLACEHOLDER = "Item 2";
const ECLIPSED = "Eclipsed";
const ALPHA = "Alpha";

const FLOWER_PRICE_REMEMBER_ME_HOLDER = 0.025;
const FLOWER_PRICE_NON_REMEMBER_ME_HOLDER = 0.25;
const MOONLIGHT_PRICE = 0.035;
const BETTA_PRICE = 0.035;

if (window.ethereum) {
  window.ethereum.on("accountsChanged", function(accounts) {
    // Time to reload your interface with accounts[0]!
    window.location.reload();
  });

  window.ethereum.on("networkChanged", function(networkId) {
    // Time to reload your interface with the new networkId
    window.location.reload();
  });
}

class App extends Component {
  _tokenCountsHelper = {
    rebirth: 0,
    betta: 0,
    moonlight: 0,
    rococoParrot: 0,
    mondial: 0,
    janVanNess: 0,
    laBelleEpoch: 0,
    jonquires: 0,
    rococoParrotAlpha: 0,
    mondialAlpha: 0,
    janVanNessAlpha: 0,
    laBelleEpochAlpha: 0,
    jonquiresAlpha: 0,
    rococoParrotEclipsed: 0,
    mondialEclipsed: 0,
    janVanNessEclipsed: 0,
    laBelleEpochEclipsed: 0,
    jonquiresEclipsed: 0,
    rococoParrotAlphaEclipsed: 0,
    mondialAlphaEclipsed: 0,
    janVanNessAlphaEclipsed: 0,
    laBelleEpochAlphaEclipsed: 0,
    jonquiresAlphaEclipsed: 0,
    bronzeBouquetOne: 0,
    bronzeBouquetTwo: 0,
    bronzeBouquetThree: 0,
    bronzeTalisman: 0,
    silverBouquetOne: 0,
    silverBouquetTwo: 0,
    silverBouquetThree: 0,
    silverTalisman: 0,
    triumph: 0,
    triumphAlpha: 0,
    triumphEclipsed: 0,
    triumphAlphaEclipsed: 0,
    goldBouquet: 0,
    goldTalisman: 0,
    platinumBouquet: 0,
    stoneTalisman: 0,
    platinumTalisman: 0,
    spiritTalisman: 0,
  };

  _idsForTokenURIHelper = {};

  constructor(props) {
    super(props);
    const intervalId = setInterval(this.timer, 428);
    this.state = {
      account: null,
      revealOneHappened: false,
      revealTwoHappened: false,
      revealThreeHappened: false,
      revealFourHappened: false,
      bouquetItemTitle: "Bronze Bouquet",
      contract: null,
      showingTab: TAB.MINT,
      length: 0,
      mintQuantity: 1,
      tokenCounts: this._tokenCountsHelper,
      // tokenURIs: [],
      idsForTokenURI: {},
      increasing: true,
      intervalId,
      isFlowerMintActive: null,
      isMoonlightMintActive: null,
      isBettaMintActive: null,
      evolutionItemOneTitle: EVOLUTION_ITEM_ONE_PLACEHOLDER,
      evolutionItemTwoTitle: EVOLUTION_ITEM_TWO_PLACEHOLDER,
      loaded: false,
      missingItemsForArrangementModalShowing: false,
      arrangementRTwoNotAvailableModalShowing: false,
      arrangementRThreeNotAvailableModalShowing: false,
      arrangementRFourNotAvailableModalShowing: false,
      preSeedMintModalShowing: false,
      errorFuckingModalShowing: false,
      mintNotActiveRevealThreeModalShowing: false,
      mintNotActiveRevealFourModalShowing: false,
      mintNotActiveGenericModalShowing: false,
      wrongNetworkModalShowing: false,
      nonEthBrowserModalShowing: false,
      numTokensToMint: 1,
      rememberMeBalance: 0,
      tokenBalance: 0,
      previewClass: undefined,
      transitionSrc: null,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  async componentWillMount() {
    await this._connectToWallet();
  }

  _connectToWallet = async () => {
    await this.loadWeb3();
    await this.loadBlockchainData();
    this.setState({ loaded: true });
    LOG(EVENTS.WALLET_CONNECTED);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    // console.log("width: ", window.innerWidth);
    // console.log("height: ", window.innerHeight);
  };

  _onMintClicked = async () => {
    LOG(EVENTS.MINT_CLICKED);
    const now = new Date();
    const mintStart = 1655132400000;
    if (this.state.account == null) {
      LOG(EVENTS.MINT_CLICKED_NOT_CONNECTED);
      await this._connectToWallet();
    } else if (this.state.contract == null) {
      LOG(EVENTS.MINT_CLICKED_WRONG_NETWORK);
      this.setState({ wrongNetworkModalShowing: true });
    } else if (
      !this.state.revealOneHappened ||
      (now < mintStart && !this.state.isFlowerMintActive)
    ) {
      LOG(EVENTS.MINT_CLICKED_PRE_MINT);
      this.setState({ preSeedMintModalShowing: true });
    } else if (
      !(
        this.state.isFlowerMintActive ||
        this.state.isMoonlightMintActive ||
        this.state.isBettaMintActive
      )
    ) {
      LOG(EVENTS.MINT_CLICKED_NO_ACTIVE_MINT);
      if (!this.state.revealThreeHappened) {
        this.setState({ mintNotActiveGenericModalShowing: true });
      } else if (!this.state.revealFourHappened) {
        this.setState({ mintNotActiveGenericModalShowing: true });
      } else {
        this.setState({ mintNotActiveGenericModalShowing: true });
      }
    } else {
      this.mint();
    }
  };

  _onIncrementMintNumber = () => {
    this.setState((prevState) => {
      const totalRedeemable = prevState.rememberMeBalance * 5;
      const redeemableLeft = totalRedeemable - this.state.tokenBalance;
      let mintLimit = 25;
      if (redeemableLeft > 0) {
        mintLimit = redeemableLeft < 25 ? redeemableLeft : 25;
      }
      if (prevState.rememberMeBalance === 1 && redeemableLeft > 0) {
        mintLimit = 5 - this.state.tokenBalance;
      }
      if (prevState.numTokensToMint >= mintLimit) {
        return {};
      }
      return {
        numTokensToMint: prevState.numTokensToMint + 1,
      };
    });
  };

  _onDecrementMintNumber = () => {
    this.setState((prevState) => {
      if (prevState.numTokensToMint <= 1) {
        return {};
      }
      return {
        numTokensToMint: prevState.numTokensToMint - 1,
      };
    });
  };

  timer = () => {
    // setState method is used to update the state
    this.setState((prevState) => {
      if (prevState.increasing) {
        if (prevState.length >= 40) {
          return {
            length: prevState.length - 1,
            increasing: false,
          };
        } else {
          return {
            length: prevState.length + 1,
          };
        }
      } else {
        if (prevState.length <= 0) {
          return {
            length: prevState.length + 1,
            increasing: true,
          };
        } else {
          return {
            length: prevState.length - 1,
          };
        }
      }
    });
  };

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      LOG(EVENTS.NON_ETH_BROWSER);
      this.setState({ nonEthBrowserModalShowing: true });
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3;
    if (!web3) {
      return;
    }
    // Load account
    const accounts = await web3.eth.getAccounts();
    // console.log("accounts: ", accounts);
    const ethBalance = await web3.eth.getBalance(accounts[0]);
    // console.log("balance: ", ethBalance);
    this.setState({ account: accounts[0] });
    const networkId = await web3.eth.net.getId();
    // console.log("networkId: ", networkId);
    const networkData = Rebirth.networks[networkId];
    // console.log("Rebirth.networks: ", Rebirth.networks);
    // console.log("networkData: ", networkData);
    if (networkData) {
      const abi = Rebirth.abi;
      const address = networkData.address;
      // console.log("address: ", address);
      const contract = new web3.eth.Contract(abi, address);
      const rememberMeContract = new web3.eth.Contract(
        ERC721.abi,
        REMEMBER_ME_CONTRACT_ADDRESS
      );
      const rememberMeBalance = await rememberMeContract.methods
        .balanceOf(this.state.account)
        .call();
      // console.log("rememberMeBalance: ", rememberMeBalance);
      // console.log("contract: ", contract);
      this.setState({ contract });
      const totalSupply = await contract.methods.totalSupply().call();

      const balance = await contract.methods
        .balanceOf(this.state.account)
        .call();
      const isFlowerMintActive = await contract.methods
        .isFlowerMintActive()
        .call();
      const isMoonlightMintActive = await contract.methods
        .isMoonlightMintActive()
        .call();
      const isBettaMintActive = await contract.methods
        .isBettaMintActive()
        .call();
      const revealOneHappened = await contract.methods
        .revealOneHappened()
        .call();
      const revealTwoHappened = await contract.methods
        .revealTwoHappened()
        .call();
      const revealThreeHappened = await contract.methods
        .revealThreeHappened()
        .call();
      const revealFourHappened = await contract.methods
        .revealFourHappened()
        .call();
      const hasUnusedGoldBouquet = await contract.methods
        .hasUnusedGoldBouquet(accounts[0])
        .call();
      const isSpiritTalismanClaimed = await contract.methods
        .isSpiritTalismanClaimed()
        .call();
      const numPlatinumTalismansClaimed = await contract.methods
        .numPlatinumTalismansClaimed()
        .call();
      const numGoldTalismansClaimed = await contract.methods
        .numGoldTalismansClaimed()
        .call();
      const numSilverTalismansClaimed = await contract.methods
        .numSilverTalismansClaimed()
        .call();
      const numBronzeTalismansClaimed = await contract.methods
        .numBronzeTalismansClaimed()
        .call();
      // console.log("hasUnusedGoldBouquet: ", hasUnusedGoldBouquet);

      const rememberMeBalanceNumber = Number(rememberMeBalance);
      const totalRedeemable = rememberMeBalanceNumber * 5;
      const redeemableLeft = totalRedeemable - balance;
      let numToMint = 1;
      if (rememberMeBalance > 0 && redeemableLeft > 0) {
        numToMint = redeemableLeft <= 25 ? redeemableLeft : 25;
      }

      this.setState({
        isFlowerMintActive,
        isMoonlightMintActive,
        isBettaMintActive,
        revealOneHappened,
        revealTwoHappened,
        revealThreeHappened,
        revealFourHappened,
        hasUnusedGoldBouquet,
        rememberMeBalance: rememberMeBalanceNumber,
        tokenBalance: balance,
        numTokensToMint: numToMint,
        isSpiritTalismanClaimed,
        numPlatinumTalismansClaimed,
        numGoldTalismansClaimed,
        numSilverTalismansClaimed,
        numBronzeTalismansClaimed,
      });

      if (rememberMeBalance === 0) {
        LOG(EVENTS.REMEMBER_ME_BALANCE_ZERO);
      } else if (rememberMeBalance === 1) {
        LOG(EVENTS.REMEMBER_ME_BALANCE_ONE);
      } else if (rememberMeBalance > 1) {
        LOG(EVENTS.REMEMBER_ME_BALANCE_MORE);
      }

      // console.log("totalSupply: ", Number(totalSupply));
      this.setState({ totalSupply: Number(totalSupply) });
      // Load Seeds
      for (let i = 0; i < balance; i++) {
        const tokenAtIndex = await contract.methods
          .tokenOfOwnerByIndex(this.state.account, i)
          .call();
        // console.log("tokenAtIndex: ", tokenAtIndex);
        const tokenURI = await contract.methods.tokenURI(tokenAtIndex).call();
        if (this._idsForTokenURIHelper[tokenURI] == null) {
          this._idsForTokenURIHelper[tokenURI] = [tokenAtIndex];
        } else {
          this._idsForTokenURIHelper[tokenURI] = [
            ...this._idsForTokenURIHelper[tokenURI],
            tokenAtIndex,
          ];
        }
        this._updateTokenCountsForURI(tokenURI);
      }
      // console.log("this.state.tokenURIs: ", this.state.tokenURIs);
      this.setState({
        tokenCounts: this._tokenCountsHelper,
        idsForTokenURI: this._idsForTokenURIHelper,
      });
      // console.log("this._idsForTokenURIHelper: ", this._idsForTokenURIHelper);
      if (balance > 0) {
        if (this.state.isBettaMintActive) {
          if (this._tokenCountsHelper.betta > 0) {
            this.setState({
              showingTab: TAB.GARDEN,
            });
          }
        } else if (this.state.isMoonlightMintActive) {
          if (this._tokenCountsHelper.moonlight > 0) {
            this.setState({
              showingTab: TAB.GARDEN,
            });
          }
        } else if (this.state.isFlowerMintActive) {
          if (this._tokenCountsHelper.rebirth > 0) {
            this.setState({
              showingTab: TAB.GARDEN,
            });
          }
        } else {
          this.setState({
            showingTab: TAB.GARDEN,
          });
        }
      }
      // console.log("this.state.tokenCounts: ", this.state.tokenCounts);
      // console.log("this.state.idsForTokenURI: ", this.state.idsForTokenURI);
      // // console.log("tokenMetadata", this.state.tokenMetadata.toString());//
    } else {
      this.setState({ wrongNetworkModalShowing: true });
    }
  }

  mint = async () => {
    if (this.state.isFlowerMintActive) {
      const priceToUse =
        this.state.tokenCounts.rebirth < this.state.rememberMeBalance * 5
          ? FLOWER_PRICE_REMEMBER_ME_HOLDER
          : FLOWER_PRICE_NON_REMEMBER_ME_HOLDER;
      // console.log(
      //   "AAAAA this.state.numTokensToMint: ",
      //   this.state.numTokensToMint
      // );
      // console.log("MINTING FLOWER");
      const web3 = window.web3;
      // console.log("WEB3: ", web3);
      const value = this.state.numTokensToMint * priceToUse;
      const valueRounded = Math.round(1000 * value) / 1000;
      // console.log("AAAAA value: ", value);
      // console.log("AAAAA priceToUse: ", priceToUse);
      this.state.contract.methods
        .mintFlower(this.state.numTokensToMint)
        .send({
          from: this.state.account,
          // to: this.state.contract.address,
          value: window.web3.utils.toWei(valueRounded.toString(), "ether"),
        })
        .once("receipt", (receipt) => {
          if (receipt.status) {
            LOG(EVENTS.MINT_SUCCESS_FLOWER);
            if (this.state.numTokensToMint === 1) {
              LOG(EVENTS.MINT_COUNT_FLOWER_ONE);
            } else if (this.state.numTokensToMint === 2) {
              LOG(EVENTS.MINT_COUNT_FLOWER_TWO);
            } else if (this.state.numTokensToMint === 3) {
              LOG(EVENTS.MINT_COUNT_FLOWER_THREE);
            } else if (this.state.numTokensToMint === 4) {
              LOG(EVENTS.MINT_COUNT_FLOWER_FOUR);
            } else if (this.state.numTokensToMint === 5) {
              LOG(EVENTS.MINT_COUNT_FLOWER_FIVE);
            } else if (this.state.numTokensToMint > 5) {
              LOG(EVENTS.MINT_COUNT_FLOWER_SIX_OR_MORE);
            }
            window.location.reload();
          }
        })
        .on("confirmation", (confNumber, receipt) => {
          // console.log("mint confNumber: ", +confNumber);
          // console.log("mint receipt: ", +receipt);

          window.location.reload();
        })
        .on("error", (error) => {
          LOG(EVENTS.MINT_ERROR_FLOWER);
          // console.log("ERROR: " + error.message);
        });
      // console.log("Finished Minting Flower");
    } else if (this.state.isBettaMintActive) {
      // console.log("MINTING BETTA");
      const web3 = window.web3;
      // console.log("WEB3: ", web3);
      const value = this.state.numTokensToMint * BETTA_PRICE;
      const valueRounded = Math.round(1000 * value) / 1000;
      // console.log("AAAAA value: ", value);
      // console.log("AAAAA valueRounded: ", valueRounded);
      this.state.contract.methods
        .mintBetta(this.state.numTokensToMint)
        .send({
          from: this.state.account,
          // to: this.state.contract.address,
          value: window.web3.utils.toWei(valueRounded.toString(), "ether"),
        })
        .once("receipt", (receipt) => {
          if (receipt.status) {
            LOG(EVENTS.MINT_SUCCESS_BETTA);
            if (this.state.numTokensToMint === 1) {
              LOG(EVENTS.MINT_COUNT_BETTA_ONE);
            } else if (this.state.numTokensToMint === 2) {
              LOG(EVENTS.MINT_COUNT_BETTA_TWO);
            } else if (this.state.numTokensToMint === 3) {
              LOG(EVENTS.MINT_COUNT_BETTA_THREE);
            } else if (this.state.numTokensToMint === 4) {
              LOG(EVENTS.MINT_COUNT_BETTA_FOUR);
            } else if (this.state.numTokensToMint === 5) {
              LOG(EVENTS.MINT_COUNT_BETTA_FIVE);
            } else if (this.state.numTokensToMint > 5) {
              LOG(EVENTS.MINT_COUNT_BETTA_SIX_OR_MORE);
            }
            window.location.reload();
          }
        })
        .on("confirmation", (confNumber, receipt) => {
          // console.log("mint confNumber: ", +confNumber);
          // console.log("mint receipt: ", +receipt);

          window.location.reload();
        })
        .on("error", (error) => {
          LOG(EVENTS.MINT_ERROR_BETTA);
          // console.log("ERROR: " + error.message);
        });
      // console.log("Finished Minting BETTA");
    } else if (this.state.isMoonlightMintActive) {
      // console.log("MINTING MOONLIGHT");
      const web3 = window.web3;
      // console.log("WEB3: ", web3);
      const value = this.state.numTokensToMint * MOONLIGHT_PRICE;
      const valueRounded = Math.round(1000 * value) / 1000;
      // console.log("AAAAA value: ", value);
      // console.log("AAAAA valueRounded: ", valueRounded);
      this.state.contract.methods
        .mintMoonlight(this.state.numTokensToMint)
        .send({
          from: this.state.account,
          // to: this.state.contract.address,
          value: window.web3.utils.toWei(valueRounded.toString(), "ether"),
        })
        .once("receipt", (receipt) => {
          if (receipt.status) {
            LOG(EVENTS.MINT_SUCCESS_MOONLIGHT);
            if (this.state.numTokensToMint === 1) {
              LOG(EVENTS.MINT_COUNT_MOONLIGHT_ONE);
            } else if (this.state.numTokensToMint === 2) {
              LOG(EVENTS.MINT_COUNT_MOONLIGHT_TWO);
            } else if (this.state.numTokensToMint === 3) {
              LOG(EVENTS.MINT_COUNT_MOONLIGHT_THREE);
            } else if (this.state.numTokensToMint === 4) {
              LOG(EVENTS.MINT_COUNT_MOONLIGHT_FOUR);
            } else if (this.state.numTokensToMint === 5) {
              LOG(EVENTS.MINT_COUNT_MOONLIGHT_FIVE);
            } else if (this.state.numTokensToMint > 5) {
              LOG(EVENTS.MINT_COUNT_MOONLIGHT_SIX_OR_MORE);
            }
            window.location.reload();
          }
        })
        .on("confirmation", (confNumber, receipt) => {
          // console.log("mint confNumber: ", +confNumber);
          // console.log("mint receipt: ", +receipt);

          window.location.reload();
        })
        .on("error", (error) => {
          LOG(EVENTS.MINT_ERROR_MOONLIGHT);
          // console.log("ERROR: " + error.message);
        });
      // console.log("Finished Minting MOONLIGHT");
    }
  };

  _onTransitionSet = (transitionSrc: string) => {
    this.setState({ transitionClass: "fader" }, () => {
      setTimeout(() => {
        this.setState({ transitionSrc: transitionSrc }, () => {
          setTimeout(() => {
            window.location.reload();
          }, 15000);
        });
      }, 1000);
    });
  };

  _onClickMint = () => {
    this.setState({ showingTab: TAB.MINT });
    LOG(EVENTS.TAB_SELECTED_MINT);
  };

  _onClickGarden = () => {
    this.setState({ showingTab: TAB.GARDEN });
    LOG(EVENTS.TAB_SELECTED_GARDEN);
  };

  _onClickLab = () => {
    this.setState({ showingTab: TAB.LAB });
    LOG(EVENTS.TAB_SELECTED_LAB);
  };

  _onClickRememberMe = () => {
    this.setState({ showingTab: TAB.REMEMBER_ME });
    LOG(EVENTS.TAB_SELECTED_MEMORY);
  };

  _onClickOpenSea = () => {
    LOG(EVENTS.ICON_SELECTED_OPENSEA);
  };

  _onClickInstagram = () => {
    LOG(EVENTS.ICON_SELECTED_INSTAGRAM);
  };

  _onClickTwitter = () => {
    LOG(EVENTS.ICON_SELECTED_TWITTER);
  };

  _onEvolutionItemOneSelected = (eventKey: string) => {
    this.setState({ evolutionItemOneTitle: eventKey });
    if (eventKey.includes(ECLIPSED)) {
      if (this.state.tokenCounts.betta > 0) {
        this.setState({
          evolutionItemTwoTitle:
            "Betta (" + this.state.tokenCounts.betta + "x)",
        });
      } else {
        this.setState({
          evolutionItemTwoTitle: EVOLUTION_ITEM_TWO_PLACEHOLDER,
        });
      }
    }
    if (eventKey.includes(ALPHA) || eventKey.includes("Moonlight")) {
      if (this.state.tokenCounts.moonlight > 0) {
        this.setState({
          evolutionItemTwoTitle:
            "Moonlight (" + this.state.tokenCounts.moonlight + "x)",
        });
      } else {
        this.setState({
          evolutionItemTwoTitle: EVOLUTION_ITEM_TWO_PLACEHOLDER,
        });
      }
    }
  };

  _onEvolutionItemTwoSelected = (eventKey: string) => {
    this.setState({ evolutionItemTwoTitle: eventKey });
  };

  _getEvolveTransitionSrcForTitles = () => {
    const title = this.state.evolutionItemOneTitle;
    const titleTwo = this.state.evolutionItemTwoTitle;

    if (title.includes("Rococo")) {
      if (title.includes(ECLIPSED)) {
        return transitionRococoParrotEclipsedAlpha;
      } else if (title.includes(ALPHA)) {
        return transitionRococoParrotAlphaEclipsed;
      } else {
        if (titleTwo.includes("Moonlight")) {
          return transitionRococoParrotEclipsed;
        } else {
          return transitionRococoParrotAlpha;
        }
      }
    }
    if (title.includes("Mondial")) {
      if (title.includes(ECLIPSED)) {
        return transitionMondialEclipsedAlpha;
      } else if (title.includes(ALPHA)) {
        return transitionMondialAlphaEclipsed;
      } else {
        if (titleTwo.includes("Moonlight")) {
          return transitionMondialEclipsed;
        } else {
          return transitionMondialAlpha;
        }
      }
    }
    if (title.includes("Jan Van Ness")) {
      if (title.includes(ECLIPSED)) {
        return transitionJanVanNessEclipsedAlpha;
      } else if (title.includes(ALPHA)) {
        return transitionJanVanNessAlphaEclipsed;
      } else {
        if (titleTwo.includes("Moonlight")) {
          return transitionJanVanNessEclipsed;
        } else {
          return transitionJanVanNessAlpha;
        }
      }
    }
    if (title.includes("La Belle Epoch")) {
      if (title.includes(ECLIPSED)) {
        return transitionLaBelleEpochEclipsedAlpha;
      } else if (title.includes(ALPHA)) {
        return transitionLaBelleEpochAlphaEclipsed;
      } else {
        if (titleTwo.includes("Moonlight")) {
          return transitionLaBelleEpochEclipsed;
        } else {
          return transitionLaBelleEpochAlpha;
        }
      }
    }
    if (title.includes("Jonquires")) {
      if (title.includes(ECLIPSED)) {
        return transitionJonquiresEclipsedAlpha;
      } else if (title.includes(ALPHA)) {
        return transitionJonquiresAlphaEclipsed;
      } else {
        if (titleTwo.includes("Moonlight")) {
          return transitionJonquiresEclipsed;
        } else {
          return transitionJonquiresAlpha;
        }
      }
    }
    if (title.includes("Triumph")) {
      if (title.includes(ECLIPSED)) {
        return transitionTriumphAlphaEclipsed; // TODO transitionTriumphEclipsedAlpha;
      } else if (title.includes(ALPHA)) {
        return transitionTriumphAlphaEclipsed;
      } else {
        if (titleTwo.includes("Moonlight")) {
          return transitionTriumphEclipsed;
        } else {
          return transitionTriumphAlpha;
        }
      }
    }
    if (title.includes("Moonlight")) {
      return transitionTriumph;
    }
  };

  _getTokenIdForTitle = (title: string, getSecond?: ?boolean) => {
    let IDs;
    let baseURI = BASE_URIS.REVEAL_ONE;
    if (this.state.revealTwoHappened) {
      baseURI = BASE_URIS.REVEAL_TWO;
    }
    if (this.state.revealThreeHappened) {
      baseURI = BASE_URIS.REVEAL_THREE;
    }
    if (this.state.revealFourHappened) {
      baseURI = BASE_URIS.REVEAL_FOUR;
    }

    if (title.includes("Moonlight")) {
      IDs = this.state.idsForTokenURI[baseURI + URIS.MOONLIGHT];
    }
    if (title.includes("Betta")) {
      IDs = this.state.idsForTokenURI[baseURI + URIS.BETTA];
    }
    if (title.includes("Rococo")) {
      if (title.includes(ECLIPSED) && title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[
          baseURI + URIS.FLOWER_ONE_ALPHA_ECLIPSED
        ];
      } else if (title.includes(ECLIPSED)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_ONE_ECLIPSED];
      } else if (title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_ONE_ALPHA];
      } else {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_ONE];
      }
    }
    if (title.includes("Mondial")) {
      if (title.includes(ECLIPSED) && title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[
          baseURI + URIS.FLOWER_TWO_ALPHA_ECLIPSED
        ];
      } else if (title.includes(ECLIPSED)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_TWO_ECLIPSED];
      } else if (title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_TWO_ALPHA];
      } else {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_TWO];
      }
    }
    if (title.includes("Jan Van Ness")) {
      if (title.includes(ECLIPSED) && title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[
          baseURI + URIS.FLOWER_THREE_ALPHA_ECLIPSED
        ];
      } else if (title.includes(ECLIPSED)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_THREE_ECLIPSED];
      } else if (title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_THREE_ALPHA];
      } else {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_THREE];
      }
    }
    if (title.includes("La Belle Epoch")) {
      if (title.includes(ECLIPSED) && title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[
          baseURI + URIS.FLOWER_FOUR_ALPHA_ECLIPSED
        ];
      } else if (title.includes(ECLIPSED)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_FOUR_ECLIPSED];
      } else if (title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_FOUR_ALPHA];
      } else {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_FOUR];
      }
    }
    if (title.includes("Jonquires")) {
      if (title.includes(ECLIPSED) && title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[
          baseURI + URIS.FLOWER_FIVE_ALPHA_ECLIPSED
        ];
      } else if (title.includes(ECLIPSED)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_FIVE_ECLIPSED];
      } else if (title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_FIVE_ALPHA];
      } else {
        IDs = this.state.idsForTokenURI[baseURI + URIS.FLOWER_FIVE];
      }
    }
    if (title.includes("Triumph")) {
      if (title.includes(ECLIPSED) && title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.TRIUMPH_ALPHA_ECLIPSED];
      } else if (title.includes(ECLIPSED)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.TRIUMPH_ECLIPSED];
      } else if (title.includes(ALPHA)) {
        IDs = this.state.idsForTokenURI[baseURI + URIS.TRIUMPH_ALPHA];
      } else {
        IDs = this.state.idsForTokenURI[baseURI + URIS.TRIUMPH];
      }
    }
    if (getSecond && IDs != null && IDs.length > 1) {
      return IDs[IDs.length - 2];
    } else if (IDs != null && IDs.length > 0) {
      return IDs[IDs.length - 1];
    }
    return -1;
  };

  _onClickLabTabEvolution = () => {
    LOG(EVENTS.LAB_SUBTAB_CLICKED_EVOLUTION);
  };

  _onClickLabTabFestival = () => {
    LOG(EVENTS.LAB_SUBTAB_CLICKED_FESTIVAL);
  };

  _onFuckSelected = () => {
    LOG(EVENTS.EVOLVE_CLICKED);
    if (
      this.state.evolutionItemOneTitle === EVOLUTION_ITEM_ONE_PLACEHOLDER ||
      this.state.evolutionItemTwoTitle === EVOLUTION_ITEM_TWO_PLACEHOLDER
    ) {
      this.setState({ errorFuckingModalShowing: true });
      LOG(EVENTS.EVOLVE_FAILED);
      return;
    }
    const tokenIdOne = this._getTokenIdForTitle(
      this.state.evolutionItemOneTitle
    );
    const tokenIdTwo = this._getTokenIdForTitle(
      this.state.evolutionItemTwoTitle,
      this.state.evolutionItemOneTitle === this.state.evolutionItemTwoTitle
    );
    const transitionSrc = this._getEvolveTransitionSrcForTitles();
    // console.log("FUCKING");
    const web3 = window.web3;
    // console.log("WEB3: ", web3);
    this.state.contract.methods
      .evolve(tokenIdOne, tokenIdTwo)
      .send({
        from: this.state.account,
        to: this.state.contract.address,
      })
      .once("receipt", (receipt) => {
        if (receipt.status) {
          LOG(EVENTS.EVOLVE_SUCCESS);
          this._onTransitionSet(transitionSrc);
        }
      })
      .on("confirmation", (confNumber, receipt) => {
        // console.log("fuck confNumber: ", +confNumber);
        // console.log("fuck receipt: ", +receipt);
      })
      .on("error", (error) => {
        LOG(EVENTS.EVOLVE_ERROR);
        // console.log("ERROR: " + error.message);
      });
    // console.log("Finished Fucking");
  };

  _onArrangeSelected = () => {
    LOG(EVENTS.ARRANGE_CLICKED);
    const bouquetTitle = this.state.bouquetItemTitle;
    if (
      (bouquetTitle === CONSTANTS.GOLD_BOUQUET ||
        bouquetTitle === CONSTANTS.PLATINUM_BOUQUET) &&
      !this.state.revealFourHappened
    ) {
      this.setState({ arrangementRFourNotAvailableModalShowing: true });
      return;
    }
    if (
      bouquetTitle === CONSTANTS.SILVER_BOUQUET &&
      !this.state.revealThreeHappened
    ) {
      this.setState({ arrangementRThreeNotAvailableModalShowing: true });
      return;
    }
    if (
      bouquetTitle === CONSTANTS.BRONZE_BOUQUET &&
      !this.state.revealTwoHappened
    ) {
      this.setState({ arrangementRTwoNotAvailableModalShowing: true });
      return;
    }

    let canNotMakeArrangement = false;
    if (bouquetTitle === CONSTANTS.BRONZE_BOUQUET) {
      if (
        this.state.tokenCounts.jonquires < 1 ||
        this.state.tokenCounts.laBelleEpoch < 1 ||
        this.state.tokenCounts.janVanNess < 1 ||
        this.state.tokenCounts.mondial < 1 ||
        this.state.tokenCounts.rococoParrot < 1
      ) {
        canNotMakeArrangement = true;
      }
    }
    if (bouquetTitle === CONSTANTS.SILVER_BOUQUET) {
      if (
        this.state.tokenCounts.jonquiresAlpha < 1 ||
        this.state.tokenCounts.laBelleEpochAlpha < 1 ||
        this.state.tokenCounts.janVanNessAlpha < 1 ||
        this.state.tokenCounts.mondialAlpha < 1 ||
        this.state.tokenCounts.rococoParrotAlpha < 1
      ) {
        canNotMakeArrangement = true;
      }
    }
    if (bouquetTitle === CONSTANTS.GOLD_BOUQUET) {
      if (
        this.state.tokenCounts.jonquiresEclipsed < 1 ||
        this.state.tokenCounts.laBelleEpochEclipsed < 1 ||
        this.state.tokenCounts.janVanNessEclipsed < 1 ||
        this.state.tokenCounts.mondialEclipsed < 1 ||
        this.state.tokenCounts.rococoParrotEclipsed < 1
      ) {
        canNotMakeArrangement = true;
      }
    }
    if (bouquetTitle === CONSTANTS.PLATINUM_BOUQUET) {
      if (
        this.state.tokenCounts.jonquiresAlphaEclipsed < 1 ||
        this.state.tokenCounts.laBelleEpochAlphaEclipsed < 1 ||
        this.state.tokenCounts.janVanNessAlphaEclipsed < 1 ||
        this.state.tokenCounts.mondialAlphaEclipsed < 1 ||
        this.state.tokenCounts.rococoParrotAlphaEclipsed < 1
      ) {
        canNotMakeArrangement = true;
      }
    }

    if (canNotMakeArrangement) {
      LOG(EVENTS.ARRANGE_FAILED);
      this.setState({ missingItemsForArrangementModalShowing: true });
      return;
    }

    let arrangement = 0;
    let transitionSrc = transitionBronzeBouquet;
    if (!this.state.numBronzeTalismansClaimed < 25) {
      transitionSrc = transitionBronzeBouquetBronzeTalisman;
    }
    if (bouquetTitle === CONSTANTS.SILVER_BOUQUET) {
      arrangement = 1;
      if (!this.state.numSilverTalismansClaimed < 20) {
        transitionSrc = transitionSilverBouquetSilverTalisman;
      } else {
        transitionSrc = transitionSilverBouquet;
      }
    }
    if (bouquetTitle === CONSTANTS.GOLD_BOUQUET) {
      arrangement = 2;
      if (!this.state.numGoldTalismansClaimed < 15) {
        transitionSrc = transitionGoldBouquetGoldTalisman;
      } else {
        transitionSrc = transitionGoldBouquet;
      }
    }
    if (bouquetTitle === CONSTANTS.PLATINUM_BOUQUET) {
      arrangement = 3;
      if (!this.state.isSpiritTalismanClaimed) {
        transitionSrc = transitionPlatinumBouquetSpiritTalisman;
      } else if (this.state.numPlatinumTalismansClaimed < 10) {
        transitionSrc = transitionPlatinumBouquetPlatinumTalisman;
      } else {
        transitionSrc = transitionPlatinumBouquetStoneTalisman;
      }
    }

    // console.log("ARRANGING");
    const web3 = window.web3;
    // console.log("WEB3: ", web3);
    this.state.contract.methods
      .arrange(arrangement)
      .send({
        from: this.state.account,
        to: this.state.contract.address,
      })
      .once("receipt", (receipt) => {
        if (receipt.status) {
          LOG(EVENTS.ARRANGE_SUCCESS);
          this._onTransitionSet(transitionSrc);
        }
      })
      .on("confirmation", (confNumber, receipt) => {
        // console.log("arrange confNumber: ", +confNumber);
        // console.log("arrange receipt: ", +receipt);
      })
      .on("error", (error) => {
        LOG(EVENTS.ARRANGE_ERROR);
        // console.log("ERROR: " + error.message);
      });
    // console.log("Finished Arranging");
  };

  _onSacrificeSelected = () => {
    // console.log("SACRIFICING");
    const web3 = window.web3;
    // console.log("WEB3: ", web3);
    this.state.contract.methods
      .sacrifice()
      .send({
        from: this.state.account,
        to: this.state.contract.address,
      })
      .once("receipt", (receipt) => {
        if (receipt.status) {
          window.location.reload();
        }
      })
      .on("confirmation", (confNumber, receipt) => {
        // console.log("sacrifice confNumber: ", +confNumber);
        // console.log("sacrific receipt: ", +receipt);
        window.location.reload();
      })
      .on("error", (error) => {
        // console.log("ERROR: " + error.message);
      });
    // console.log("Finished sacrificing");
  };

  _onBouquetItemSelected = (eventKey: string) => {
    this.setState({ bouquetItemTitle: eventKey });
  };

  _updateTokenCountsForURI = (uri: string) => {
    if (!this.state.revealTwoHappened) {
      this._tokenCountsHelper = {
        ...this._tokenCountsHelper,
        rebirth: this._tokenCountsHelper.rebirth + 1,
      };
      return;
    }
    // Base URI will change, suffix will not
    const suffix = uri.split("/").pop();
    switch (suffix) {
      case URIS.BETTA:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          betta: this._tokenCountsHelper.betta + 1,
        };
        break;
      case URIS.MOONLIGHT:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          moonlight: this._tokenCountsHelper.moonlight + 1,
        };
        break;
      case URIS.FLOWER_ONE:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          rococoParrot: this._tokenCountsHelper.rococoParrot + 1,
        };
        break;
      case URIS.FLOWER_TWO:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          mondial: this._tokenCountsHelper.mondial + 1,
        };
        break;
      case URIS.FLOWER_THREE:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          janVanNess: this._tokenCountsHelper.janVanNess + 1,
        };
        break;
      case URIS.FLOWER_FOUR:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          laBelleEpoch: this._tokenCountsHelper.laBelleEpoch + 1,
        };
        break;
      case URIS.FLOWER_FIVE:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          jonquires: this._tokenCountsHelper.jonquires + 1,
        };
        break;
      case URIS.FLOWER_ONE_ALPHA:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          rococoParrotAlpha: this._tokenCountsHelper.rococoParrotAlpha + 1,
        };
        break;
      case URIS.FLOWER_TWO_ALPHA:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          mondialAlpha: this._tokenCountsHelper.mondialAlpha + 1,
        };
        break;
      case URIS.FLOWER_THREE_ALPHA:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          janVanNessAlpha: this._tokenCountsHelper.janVanNessAlpha + 1,
        };
        break;
      case URIS.FLOWER_FOUR_ALPHA:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          laBelleEpochAlpha: this._tokenCountsHelper.laBelleEpochAlpha + 1,
        };
        break;
      case URIS.FLOWER_FIVE_ALPHA:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          jonquiresAlpha: this._tokenCountsHelper.jonquiresAlpha + 1,
        };
        break;
      case URIS.FLOWER_ONE_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          rococoParrotEclipsed:
            this._tokenCountsHelper.rococoParrotEclipsed + 1,
        };
        break;
      case URIS.FLOWER_TWO_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          mondialEclipsed: this._tokenCountsHelper.mondialEclipsed + 1,
        };
        break;
      case URIS.FLOWER_THREE_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          janVanNessEclipsed: this._tokenCountsHelper.janVanNessEclipsed + 1,
        };
        break;
      case URIS.FLOWER_FOUR_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          laBelleEpochEclipsed:
            this._tokenCountsHelper.laBelleEpochEclipsed + 1,
        };
        break;
      case URIS.FLOWER_FIVE_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          jonquiresEclipsed: this._tokenCountsHelper.jonquiresEclipsed + 1,
        };
        break;
      case URIS.FLOWER_ONE_ALPHA_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          rococoParrotAlphaEclipsed:
            this._tokenCountsHelper.rococoParrotAlphaEclipsed + 1,
        };
        break;
      case URIS.FLOWER_TWO_ALPHA_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          mondialAlphaEclipsed:
            this._tokenCountsHelper.mondialAlphaEclipsed + 1,
        };
        break;
      case URIS.FLOWER_THREE_ALPHA_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          janVanNessAlphaEclipsed:
            this._tokenCountsHelper.janVanNessAlphaEclipsed + 1,
        };
        break;
      case URIS.FLOWER_FOUR_ALPHA_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          laBelleEpochAlphaEclipsed:
            this._tokenCountsHelper.laBelleEpochAlphaEclipsed + 1,
        };
        break;
      case URIS.FLOWER_FIVE_ALPHA_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          jonquiresAlphaEclipsed:
            this._tokenCountsHelper.jonquiresAlphaEclipsed + 1,
        };
        break;
      case URIS.BRONZE_BOUQUET_ONE:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          bronzeBouquetOne: this._tokenCountsHelper.bronzeBouquetOne + 1,
        };
        break;
      case URIS.BRONZE_BOUQUET_TWO:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          bronzeBouquetTwo: this._tokenCountsHelper.bronzeBouquetTwo + 1,
        };
        break;
      case URIS.BRONZE_BOUQUET_THREE:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          bronzeBouquetThree: this._tokenCountsHelper.bronzeBouquetThree + 1,
        };
        break;
      case URIS.BRONZE_TALISMAN:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          bronzeTalisman: this._tokenCountsHelper.bronzeTalisman + 1,
        };
        break;
      case URIS.SILVER_BOUQUET_ONE:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          silverBouquetOne: this._tokenCountsHelper.silverBouquetOne + 1,
        };
        break;
      case URIS.SILVER_BOUQUET_TWO:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          silverBouquetTwo: this._tokenCountsHelper.silverBouquetTwo + 1,
        };
        break;
      case URIS.SILVER_BOUQUET_THREE:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          silverBouquetThree: this._tokenCountsHelper.silverBouquetThree + 1,
        };
        break;
      case URIS.SILVER_TALISMAN:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          silverTalisman: this._tokenCountsHelper.silverTalisman + 1,
        };
        break;
      case URIS.TRIUMPH:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          triumph: this._tokenCountsHelper.triumph + 1,
        };
        break;
      case URIS.TRIUMPH_ALPHA:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          triumphAlpha: this._tokenCountsHelper.triumphAlpha + 1,
        };
        break;
      case URIS.TRIUMPH_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          triumphEclipsed: this._tokenCountsHelper.triumphEclipsed + 1,
        };
        break;
      case URIS.TRIUMPH_ALPHA_ECLIPSED:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          triumphAlphaEclipsed:
            this._tokenCountsHelper.triumphAlphaEclipsed + 1,
        };
        break;
      case URIS.GOLD_BOUQUET:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          goldBouquet: this._tokenCountsHelper.goldBouquet + 1,
        };
        break;
      case URIS.GOLD_TALISMAN:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          goldTalisman: this._tokenCountsHelper.goldTalisman + 1,
        };
        break;
      case URIS.PLATINUM_BOUQUET: {
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          platinumBouquet: this._tokenCountsHelper.platinumBouquet + 1,
        };
        break;
      }
      case URIS.STONE_TALISMAN:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          stoneTalisman: this._tokenCountsHelper.stoneTalisman + 1,
        };
        break;
      case URIS.PLATINUM_TALISMAN:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          platinumTalisman: this._tokenCountsHelper.platinumTalisman + 1,
        };
        break;
      case URIS.SPIRIT_TALISMAN:
        this._tokenCountsHelper = {
          ...this._tokenCountsHelper,
          spiritTalisman: this._tokenCountsHelper.spiritTalisman + 1,
        };
        break;
      default:
        break;
    }
  };

  render() {
    const MINT_CONTENT = (
      <MintScreen
        revealOneHappened={this.state.revealOneHappened}
        revealTwoHappened={this.state.revealTwoHappened}
        revealThreeHappened={this.state.revealThreeHappened}
        revealFourHappened={this.state.revealFourHappened}
        isFlowerMintActive={this.state.isFlowerMintActive}
        isMoonlightMintActive={this.state.isMoonlightMintActive}
        isBettaMintActive={this.state.isBettaMintActive}
        numRebirth={this.state.tokenCounts.rebirth}
        numMemories={this.state.rememberMeBalance}
        numToMint={this.state.numTokensToMint}
        onDecrementMintNumber={this._onDecrementMintNumber}
        onIncrementMintNumber={this._onIncrementMintNumber}
        onMintClicked={this._onMintClicked}
        height={this.state.height}
        width={this.state.width}
      />
    );

    // // console.log(
    //   "this.state.tokenMetadata.length: ",
    //   this.state.tokenMetadata.length
    // );

    const content =
      this.state.loaded === false ? (
        <div
          style={{
            height: this.state.height,
            width: this.state.width,
            backgroundColor: "#000000",
          }}
        />
      ) : this.state.showingTab === TAB.MINT ? (
        MINT_CONTENT
      ) : this.state.showingTab === TAB.GARDEN ? (
        <GardenScreen
          revealOneHappened={this.state.revealOneHappened}
          revealTwoHappened={this.state.revealTwoHappened}
          revealThreeHappened={this.state.revealThreeHappened}
          revealFourHappened={this.state.revealFourHappened}
          hasUnusedGoldBouquet={this.state.hasUnusedGoldBouquet}
          height={this.state.height}
          tokenCounts={this.state.tokenCounts}
          width={this.state.width}
        />
      ) : this.state.showingTab === TAB.LAB ? (
        <LabScreen
          revealOneHappened={this.state.revealOneHappened}
          revealTwoHappened={this.state.revealTwoHappened}
          revealThreeHappened={this.state.revealThreeHappened}
          revealFourHappened={this.state.revealFourHappened}
          bouquetItemTitle={this.state.bouquetItemTitle}
          evolutionItemOneTitle={this.state.evolutionItemOneTitle}
          evolutionItemTwoTitle={this.state.evolutionItemTwoTitle}
          hasUnusedGoldBouquet={this.state.hasUnusedGoldBouquet}
          tokenCounts={this.state.tokenCounts}
          height={this.state.height}
          onClickLabTabEvolution={this._onClickLabTabEvolution}
          onClickLabTabFestival={this._onClickLabTabFestival}
          onBouquetItemSelected={this._onBouquetItemSelected}
          onEvolutionItemOneSelected={this._onEvolutionItemOneSelected}
          onEvolutionItemTwoSelected={this._onEvolutionItemTwoSelected}
          onArrangeSelected={this._onArrangeSelected}
          onFuckSelected={this._onFuckSelected}
          onSacrificeSelected={this._onSacrificeSelected}
          width={this.state.width}
        />
      ) : this.state.showingTab === TAB.REMEMBER_ME ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "column",
            height: this.state.height,
            width: "100%",
          }}
          class="fader"
        >
          <video
            // onContextMenu={(e) => e.preventDefault()}
            style={{
              width: "100%",
              height:
                this.state.width > 508 ||
                this.state.width / this.state.height > 2160 / 3840
                  ? this.state.height
                  : undefined,
              objectFit: "contain",
            }}
            autoPlay={true}
            loop={false}
            muted={false}
            playsInline={true}
            src={ourStory}
          />
        </div>
      ) : (
        MINT_CONTENT
      );

    return (
      <div
        style={{
          height: this.state.height,
          width: this.state.width,
        }}
      >
        {this.state.transitionClass == null && (
          <WFNavBar
            account={this.state.account}
            connectToWallet={this._connectToWallet}
            onClickMint={this._onClickMint}
            onClickGarden={this._onClickGarden}
            onClickLab={this._onClickLab}
            onClickRememberMe={this._onClickRememberMe}
            onClickOpenSea={this._onClickOpenSea}
            onClickInstagram={this._onClickInstagram}
            onClickTwitter={this._onClickTwitter}
            showingTab={this.state.showingTab}
            width={this.state.width}
          />
        )}
        {content}
        <WFModal
          body={"collect all five flowers to arrange this bouquet"}
          title="missing flowers for bouquet"
          showing={this.state.missingItemsForArrangementModalShowing}
          onHide={() => {
            this.setState({ missingItemsForArrangementModalShowing: false });
          }}
        />
        <WFModal
          body={"create this bouquet on " + CONSTANTS.REVEAL_TWO_DATE}
          title="arrangement not available yet"
          showing={this.state.arrangementRTwoNotAvailableModalShowing}
          onHide={() => {
            this.setState({ arrangementRTwoNotAvailableModalShowing: false });
          }}
        />
        <WFModal
          body={"create this bouquet on " + CONSTANTS.REVEAL_THREE_DATE}
          title="arrangement not available yet"
          showing={this.state.arrangementRThreeNotAvailableModalShowing}
          onHide={() => {
            this.setState({ arrangementRThreeNotAvailableModalShowing: false });
          }}
        />
        <WFModal
          body={"create this bouquet on " + CONSTANTS.REVEAL_FOUR_DATE}
          title="arrangement not available yet"
          showing={this.state.arrangementRFourNotAvailableModalShowing}
          onHide={() => {
            this.setState({ arrangementRFourNotAvailableModalShowing: false });
          }}
        />
        <WFModal
          body={"mint opens " + CONSTANTS.REVEAL_ONE_DATE + " at 8am PST"}
          title="mint not open yet"
          showing={this.state.preSeedMintModalShowing}
          onHide={() => {
            this.setState({ preSeedMintModalShowing: false });
          }}
        />
        <WFModal
          body="connect to the ethereum mainnet to get started"
          title="wrong network"
          showing={this.state.wrongNetworkModalShowing}
          onHide={() => {
            this.setState({ wrongNetworkModalShowing: false });
          }}
        />
        <WFModal
          body="afterlife.garden requires an ethereum-friendly browser for full functionality. try chrome's metamask plugin."
          title="non-ethereum browser detected"
          showing={this.state.nonEthBrowserModalShowing}
          onHide={() => {
            this.setState({ nonEthBrowserModalShowing: false });
          }}
        />
        <WFModal
          body={
            "flower mint closed. next mint available 12pm PST on " +
            CONSTANTS.REVEAL_THREE_DATE +
            ". follow along on twitter for updates."
          }
          title="mint opening soon"
          showing={this.state.mintNotActiveRevealThreeModalShowing}
          onHide={() => {
            this.setState({ mintNotActiveRevealThreeModalShowing: false });
          }}
        />
        <WFModal
          body={
            "betta mint closed. next mint available 12pm PST on " +
            CONSTANTS.REVEAL_FOUR_DATE +
            ". follow along on twitter for updates."
          }
          title="mint opening soon"
          showing={this.state.mintNotActiveRevealFourModalShowing}
          onHide={() => {
            this.setState({ mintNotActiveRevealFourModalShowing: false });
          }}
        />
        <WFModal
          body="no mint is currently active. follow along on twitter for mint time updates."
          title="no active mint"
          showing={this.state.mintNotActiveGenericModalShowing}
          onHide={() => {
            this.setState({ mintNotActiveGenericModalShowing: false });
          }}
        />
        <WFModal
          body="missing items needed to evolve"
          title="can't evolve yet"
          showing={this.state.errorFuckingModalShowing}
          onHide={() => {
            this.setState({ errorFuckingModalShowing: false });
          }}
        />
        <div
          class={this.state.transitionClass}
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            pointerEvents: "none",
            height: this.state.height,
            width: this.state.width,
            backgroundColor: "#000000",
          }}
        >
          {this.state.transitionSrc != null && (
            <video
              onContextMenu={(e) => e.preventDefault()}
              style={{
                width: "100%",
                height:
                  this.state.width > 508 ||
                  this.state.width / this.state.height > 2160 / 3840
                    ? this.state.height
                    : undefined,
                objectFit: "contain",
              }}
              autoPlay={true}
              loop={false}
              muted={false}
              playsInline={false}
              src={this.state.transitionSrc}
            />
          )}
        </div>
      </div>
    );
  }
}
export default App;
