export const BASE_URIS = {
  REVEAL_ONE:
    "https://bafybeib42nzpzyypj4c2rwe5awekw245digeakkhlmjc4fggohebatukcq.ipfs.nftstorage.link/",
  REVEAL_TWO:
    "https://bafybeia67x3s5tbxzf7xg7yc7u4dbywrwilu7elovimzqa4ofhuxheuyea.ipfs.nftstorage.link/",
  REVEAL_THREE:
    "https://bafybeihghmtwu3igqwqpmfbkowam7i6rfsvcz3gswuravhwpav4qfuefma.ipfs.nftstorage.link/",
  REVEAL_FOUR:
    "https://bafybeifm7s4pzjfz47hfbl4obo2uyfjl7wsnbqpuct54u5b2dggqgirdcy.ipfs.nftstorage.link/",
};

export const URIS = {
  // REVEAL 1
  FLOWER_ONE: "1",
  FLOWER_TWO: "2",
  FLOWER_THREE: "3",
  FLOWER_FOUR: "4",
  FLOWER_FIVE: "5",
  BRONZE_BOUQUET_ONE: "6",
  BRONZE_BOUQUET_TWO: "7",
  BRONZE_BOUQUET_THREE: "8",
  BRONZE_TALISMAN: "9",
  // REVEAL 2
  BETTA: "10",
  FLOWER_ONE_ALPHA: "11",
  FLOWER_TWO_ALPHA: "12",
  FLOWER_THREE_ALPHA: "13",
  FLOWER_FOUR_ALPHA: "14",
  FLOWER_FIVE_ALPHA: "15",
  SILVER_BOUQUET_ONE: "16",
  SILVER_BOUQUET_TWO: "17",
  SILVER_BOUQUET_THREE: "18",
  SILVER_TALISMAN: "19",
  // REVEAL 3
  MOONLIGHT: "20",
  FLOWER_ONE_ECLIPSED: "21",
  FLOWER_TWO_ECLIPSED: "22",
  FLOWER_THREE_ECLIPSED: "23",
  FLOWER_FOUR_ECLIPSED: "24",
  FLOWER_FIVE_ECLIPSED: "25",
  GOLD_BOUQUET: "26",
  GOLD_TALISMAN: "27",
  TRIUMPH: "28",
  TRIUMPH_ALPHA: "29",
  TRIUMPH_ECLIPSED: "30",
  FLOWER_ONE_ALPHA_ECLIPSED: "31",
  FLOWER_TWO_ALPHA_ECLIPSED: "32",
  FLOWER_THREE_ALPHA_ECLIPSED: "33",
  FLOWER_FOUR_ALPHA_ECLIPSED: "34",
  FLOWER_FIVE_ALPHA_ECLIPSED: "35",
  TRIUMPH_ALPHA_ECLIPSED: "36",
  PLATINUM_BOUQUET: "37",
  STONE_TALISMAN: "38",
  PLATINUM_TALISMAN: "39",
  SPIRIT_TALISMAN: "40",
};

export const IMAGES = {
  REBIRTH:
    "https://bafkreib43ezwq6wu33753w5ckw2gg7xl523t5bpvxfb5gs5pxtbn7eclze.ipfs.nftstorage.link/",
  FLOWER_ONE:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/1.jpg",
  FLOWER_TWO:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/2.jpg",
  FLOWER_THREE:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/3.jpg",
  FLOWER_FOUR:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/4.jpg",
  FLOWER_FIVE:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/5.jpg",
  BRONZE_BOUQUET_ONE:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/6.jpg",
  BRONZE_BOUQUET_TWO:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/7.jpg",
  BRONZE_BOUQUET_THREE:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/8.jpg",
  BRONZE_TALISMAN:
    "https://bafybeickgo3wmpr2qj5ctkgtbxfcp3qmcdr3p2w6467sourodx2l6dosda.ipfs.nftstorage.link/9.jpg",
  BETTA:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/10.jpg",
  FLOWER_ONE_ALPHA:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/11.jpg",
  FLOWER_TWO_ALPHA:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/12.jpg",
  FLOWER_THREE_ALPHA:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/13.jpg",
  FLOWER_FOUR_ALPHA:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/14.jpg",
  FLOWER_FIVE_ALPHA:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/15.jpg",
  SILVER_BOUQUET_ONE:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/16.jpg",
  SILVER_BOUQUET_TWO:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/17.jpg",
  SILVER_BOUQUET_THREE:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/18.jpg",
  SILVER_TALISMAN:
    "https://bafybeiesahwoecbdin57tndyafzyo6aten4i7kj724qqyhvrz7tpm7dk3u.ipfs.nftstorage.link/19.jpg",
  MOONLIGHT:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/20.jpg",
  FLOWER_ONE_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/21.jpg",
  FLOWER_TWO_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/22.jpg",
  FLOWER_THREE_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/23.jpg",
  FLOWER_FOUR_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/24.jpg",
  FLOWER_FIVE_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/25.jpg",
  GOLD_BOUQUET:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/26.jpg",
  GOLD_TALISMAN:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/27.jpg",
  TRIUMPH:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/28.jpg",
  TRIUMPH_ALPHA:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/29.jpg",
  TRIUMPH_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/30.jpg",
  FLOWER_ONE_ALPHA_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/31.jpg",
  FLOWER_TWO_ALPHA_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/32.jpg",
  FLOWER_THREE_ALPHA_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/33.jpg",
  FLOWER_FOUR_ALPHA_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/34.jpg",
  FLOWER_FIVE_ALPHA_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/35.jpg",
  TRIUMPH_ALPHA_ECLIPSED:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/36.jpg",
  PLATINUM_BOUQUET:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/37.jpg",
  STONE_TALISMAN:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/38.jpg",
  PLATINUM_TALISMAN:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/39.jpg",
  SPIRIT_TALISMAN:
    "https://bafybeifd5rpzj6mrlt4p6piu2ad75hlumcn3rejbotyioj5erb3hhac2wi.ipfs.nftstorage.link/40.jpg",
};

export const VIDEOS = {
  REBIRTH:
    "https://bafybeihocu5hh6nkixjahxu3wwfdtqpewha27i2zjtp2cqyihaue2v5wiy.ipfs.nftstorage.link/",
  FLOWER_ONE:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/1.mp4",
  FLOWER_TWO:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/2.mp4",
  FLOWER_THREE:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/3.mp4",
  FLOWER_FOUR:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/4.mp4",
  FLOWER_FIVE:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/5.mp4",
  BRONZE_BOUQUET_ONE:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/6.mp4",
  BRONZE_BOUQUET_TWO:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/7.mp4",
  BRONZE_BOUQUET_THREE:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/8.mp4",
  BRONZE_TALISMAN:
    "https://bafybeibms6wsvvxqqpxj3pkperr5rfv46cvcavy6u567mjvjxh2xazraby.ipfs.nftstorage.link/9.mp4",
  BETTA:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/10.mp4",
  FLOWER_ONE_ALPHA:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/11.mp4",
  FLOWER_TWO_ALPHA:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/12.mp4",
  FLOWER_THREE_ALPHA:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/13.mp4",
  FLOWER_FOUR_ALPHA:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/14.mp4",
  FLOWER_FIVE_ALPHA:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/15.mp4",
  SILVER_BOUQUET_ONE:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/16.mp4",
  SILVER_BOUQUET_TWO:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/17.mp4",
  SILVER_BOUQUET_THREE:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/18.mp4",
  SILVER_TALISMAN:
    "https://bafybeiet5fsbkpnz2676hvkog4ht53inurj5d6wfowtixbsxif7y3mkdke.ipfs.nftstorage.link/19.mp4",
  MOONLIGHT:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/20.mp4",
  FLOWER_ONE_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/21.mp4",
  FLOWER_TWO_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/22.mp4",
  FLOWER_THREE_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/23.mp4",
  FLOWER_FOUR_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/24.mp4",
  FLOWER_FIVE_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/25.mp4",
  GOLD_BOUQUET:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/26.mp4",
  GOLD_TALISMAN:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/27.mp4",
  TRIUMPH:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/28.mp4",
  TRIUMPH_ALPHA:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/29.mp4",
  TRIUMPH_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/30.mp4",
  FLOWER_ONE_ALPHA_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/31.mp4",
  FLOWER_TWO_ALPHA_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/32.mp4",
  FLOWER_THREE_ALPHA_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/33.mp4",
  FLOWER_FOUR_ALPHA_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/34.mp4",
  FLOWER_FIVE_ALPHA_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/35.mp4",
  TRIUMPH_ALPHA_ECLIPSED:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/36.mp4",
  PLATINUM_BOUQUET:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/37.mp4",
  STONE_TALISMAN:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/38.mp4",
  PLATINUM_TALISMAN:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/39.mp4",
  SPIRIT_TALISMAN:
    "https://bafybeico5f5hrbljdwumlajcqt6fycygddf3rvfdrsnpsz234ny2htnxni.ipfs.nftstorage.link/40.mp4",
};

export const NAMES = {
  REBIRTH: "Rebirth",
  FLOWER_ONE: "Rococo Parrot",
  FLOWER_TWO: "Mondial",
  FLOWER_THREE: "Jan Van Ness",
  FLOWER_FOUR: "La Belle Epoch",
  FLOWER_FIVE: "Jonquires",
  BRONZE_BOUQUET_ONE: "Bronze Bouquet I",
  BRONZE_BOUQUET_TWO: "Bronze Bouquet II",
  BRONZE_BOUQUET_THREE: "Bronze Bouquet II",
  BRONZE_TALISMAN: "Bronze Talisman",
  BETTA: "Betta",
  FLOWER_ONE_ALPHA: "Rococo Parrot Alpha",
  FLOWER_TWO_ALPHA: "Mondial Alpha",
  FLOWER_THREE_ALPHA: "Jan Van Ness Alpha",
  FLOWER_FOUR_ALPHA: "La Belle Epoch Alpha",
  FLOWER_FIVE_ALPHA: "Jonquires Alpha",
  SILVER_BOUQUET_ONE: "Silver Bouquet I",
  SILVER_BOUQUET_TWO: "Silver Bouquet II",
  SILVER_BOUQUET_THREE: "Silver Bouquet III",
  SILVER_TALISMAN: "Silver Talisman",
  MOONLIGHT: "Moonlight",
  FLOWER_ONE_ECLIPSED: "Rococo Parrot Eclipsed",
  FLOWER_TWO_ECLIPSED: "Mondial Eclipsed",
  FLOWER_THREE_ECLIPSED: "Jan Van Ness Eclipsed",
  FLOWER_FOUR_ECLIPSED: "La Belle Epoch Eclipsed",
  FLOWER_FIVE_ECLIPSED: "Jonquires Eclipsed",
  GOLD_BOUQUET: "Gold Bouquet",
  GOLD_TALISMAN: "Gold Talisman",
  TRIUMPH: "Triumph",
  TRIUMPH_ALPHA: "Triumph Alpha",
  TRIUMPH_ECLIPSED: "Triumph Eclipsed",
  FLOWER_ONE_ALPHA_ECLIPSED: "Eclipsed Rococo Parrot Alpha",
  FLOWER_TWO_ALPHA_ECLIPSED: "Eclipsed Mondial Alpha",
  FLOWER_THREE_ALPHA_ECLIPSED: "Eclipsed Jan Van Ness Alpha",
  FLOWER_FOUR_ALPHA_ECLIPSED: "Eclipsed La Belle Epoch Alpha",
  FLOWER_FIVE_ALPHA_ECLIPSED: "Eclipsed Jonquires Alpha",
  TRIUMPH_ALPHA_ECLIPSED: "Eclipsed Triumph Alpha",
  PLATINUM_BOUQUET: "Platinum Bouquet",
  STONE_TALISMAN: "Stone Talisman",
  PLATINUM_TALISMAN: "Platinum Talisman",
  SPIRIT_TALISMAN: "Spirit Talisman",
};

export const DESCRIPTIONS = {
  REBIRTH: "Prologue",
  FLOWER_ONE: "Transparent Rococo Parrot Tulip",
  FLOWER_TWO: "Transparent Mondial Tulip",
  FLOWER_THREE: "Transparent Jan Van Ness Tulip",
  FLOWER_FOUR: "Transparent La Belle Epoch Tulip",
  FLOWER_FIVE: "Transparent Jonquires Tulip",
  BRONZE_BOUQUET_ONE: "Bouquet of Transparent Tulips",
  BRONZE_BOUQUET_TWO: "Bouquet of Transparent Tulips",
  BRONZE_BOUQUET_THREE: "Bouquet of Transparent Tulips",
  BRONZE_TALISMAN: "Bronze Daruma Doll",
  BETTA: "Alpha",
  FLOWER_ONE_ALPHA: "Transparent Rococo Parrot Tulip With Betta",
  FLOWER_TWO_ALPHA: "Transparent Mondial Tulip With Betta",
  FLOWER_THREE_ALPHA: "Transparent Jan Van Ness Tulip With Betta",
  FLOWER_FOUR_ALPHA: "Transparent La Belle Epoch Tulip With Betta",
  FLOWER_FIVE_ALPHA: "Transparent Jonquires Tulip With Betta",
  SILVER_BOUQUET_ONE: "Bouquet of Transparent Tulips With Betta",
  SILVER_BOUQUET_TWO: "Bouquet of Transparent Tulips With Betta",
  SILVER_BOUQUET_THREE: "Bouquet of Transparent Tulips With Betta",
  SILVER_TALISMAN: "Silver Daruma Doll",
  MOONLIGHT: "Eclipse",
  FLOWER_ONE_ECLIPSED: "Eclipsed Transparent Rococo Parrot Tulip",
  FLOWER_TWO_ECLIPSED: "Eclipsed Transparent Mondial Tulip",
  FLOWER_THREE_ECLIPSED: "Eclipsed Transparent Jan Van Ness Tulip",
  FLOWER_FOUR_ECLIPSED: "Eclipsed Transparent La Belle Epoch Tulip",
  FLOWER_FIVE_ECLIPSED: "Eclipsed Transparent Jonquires Tulip",
  GOLD_BOUQUET: "Bouquet of Eclipsed Transparent Tulips",
  GOLD_TALISMAN: "Gold Daruma Doll",
  TRIUMPH: "Transparent Triumph Tulip",
  TRIUMPH_ALPHA: "Transparent Triumph Tulip With Betta",
  TRIUMPH_ECLIPSED: "Eclipsed Transparent Triumph Tulip",
  FLOWER_ONE_ALPHA_ECLIPSED:
    "Eclipsed Transparent Rococo Parrot Tulip And Betta",
  FLOWER_TWO_ALPHA_ECLIPSED: "Eclipsed Transparent Mondial Tulip And Betta",
  FLOWER_THREE_ALPHA_ECLIPSED:
    "Eclipsed Transparent Jan Van Ness Tulip And Betta",
  FLOWER_FOUR_ALPHA_ECLIPSED:
    "Eclipsed Transparent La Belle Epoch Tulip And Betta",
  FLOWER_FIVE_ALPHA_ECLIPSED: "Eclipsed Transparent Jonquires Tulip And Betta",
  TRIUMPH_ALPHA_ECLIPSED: "Eclipsed Transparent Triumph Tulip And Betta",
  PLATINUM_BOUQUET: "Eclipsed Bouquet of Transparent Tulips And Betta",
  STONE_TALISMAN: "Stone Daruma Doll",
  PLATINUM_TALISMAN: "Platinum Daruma Doll",
  SPIRIT_TALISMAN: "Spirit Daruma Doll",
};

// Class
const CLASS = "Class";
const BRONZE = "Bronze";
const SILVER = "Silver";
const GOLD = "Gold";
const PLATINUM = "Platinum";
// Family
const FAMILY = "Family";
const ESSENTIALS = "Essentials";
const TULIPS = "Tulips";
const BOUQUETS = "Bouquets";
const TALISMANS = "Talismans";

// Evolution
const EVOLUTION = "Evolution";
const ALPHA = "Alpha";
const ECLIPSED = "Eclipsed";
const ECLIPSED_ALPHA = "Eclipsed Alpha";

// Variant
const VARIANT = "Variant";
const ONE = "I";
const TWO = "II";
const THREE = "III";

export const ATTRIBUTES = {
  REBIRTH: [
    { trait_type: CLASS, value: NAMES.REBIRTH },
    { trait_type: FAMILY, value: ESSENTIALS },
  ],
  FLOWER_ONE: [
    { trait_type: CLASS, value: NAMES.FLOWER_ONE },
    { trait_type: FAMILY, value: TULIPS },
  ],
  FLOWER_TWO: [
    { trait_type: CLASS, value: NAMES.FLOWER_TWO },
    { trait_type: FAMILY, value: TULIPS },
  ],
  FLOWER_THREE: [
    { trait_type: CLASS, value: NAMES.FLOWER_THREE },
    { trait_type: FAMILY, value: TULIPS },
  ],
  FLOWER_FOUR: [
    { trait_type: CLASS, value: NAMES.FLOWER_FOUR },
    { trait_type: FAMILY, value: TULIPS },
  ],
  FLOWER_FIVE: [
    { trait_type: CLASS, value: NAMES.FLOWER_FIVE },
    { trait_type: FAMILY, value: TULIPS },
  ],
  BRONZE_BOUQUET_ONE: [
    { trait_type: CLASS, value: BRONZE },
    { trait_type: FAMILY, value: BOUQUETS },
    { trait_type: VARIANT, value: ONE },
  ],
  BRONZE_BOUQUET_TWO: [
    { trait_type: CLASS, value: BRONZE },
    { trait_type: FAMILY, value: BOUQUETS },
    { trait_type: VARIANT, value: TWO },
  ],
  BRONZE_BOUQUET_THREE: [
    { trait_type: CLASS, value: BRONZE },
    { trait_type: FAMILY, value: BOUQUETS },
    { trait_type: VARIANT, value: THREE },
  ],
  BRONZE_TALISMAN: [
    { trait_type: CLASS, value: BRONZE },
    { trait_type: FAMILY, value: TALISMANS },
    { trait_type: "25/25", value: NAMES.BRONZE_TALISMAN },
  ],
  BETTA: [
    { trait_type: CLASS, value: NAMES.BETTA },
    { trait_type: FAMILY, value: ESSENTIALS },
  ],
  FLOWER_ONE_ALPHA: [
    { trait_type: CLASS, value: NAMES.FLOWER_ONE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ALPHA },
  ],
  FLOWER_TWO_ALPHA: [
    { trait_type: CLASS, value: NAMES.FLOWER_TWO },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ALPHA },
  ],
  FLOWER_THREE_ALPHA: [
    { trait_type: CLASS, value: NAMES.FLOWER_THREE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ALPHA },
  ],
  FLOWER_FOUR_ALPHA: [
    { trait_type: CLASS, value: NAMES.FLOWER_FOUR },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ALPHA },
  ],
  FLOWER_FIVE_ALPHA: [
    { trait_type: CLASS, value: NAMES.FLOWER_FIVE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ALPHA },
  ],
  SILVER_BOUQUET_ONE: [
    { trait_type: CLASS, value: SILVER },
    { trait_type: FAMILY, value: BOUQUETS },
    { trait_type: VARIANT, value: ONE },
  ],
  SILVER_BOUQUET_TWO: [
    { trait_type: CLASS, value: SILVER },
    { trait_type: FAMILY, value: BOUQUETS },
    { trait_type: VARIANT, value: TWO },
  ],
  SILVER_BOUQUET_THREE: [
    { trait_type: CLASS, value: SILVER },
    { trait_type: FAMILY, value: BOUQUETS },
    { trait_type: VARIANT, value: THREE },
  ],
  SILVER_TALISMAN: [
    { trait_type: CLASS, value: SILVER },
    { trait_type: FAMILY, value: TALISMANS },
    { trait_type: "20/20", value: NAMES.SILVER_TALISMAN },
  ],
  MOONLIGHT: [
    { trait_type: CLASS, value: NAMES.MOONLIGHT },
    { trait_type: FAMILY, value: ESSENTIALS },
  ],
  FLOWER_ONE_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_ONE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED },
  ],
  FLOWER_TWO_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_TWO },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED },
  ],
  FLOWER_THREE_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_THREE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED },
  ],
  FLOWER_FOUR_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_FOUR },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED },
  ],
  FLOWER_FIVE_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_FIVE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED },
  ],
  GOLD_BOUQUET: [
    { trait_type: CLASS, value: GOLD },
    { trait_type: FAMILY, value: BOUQUETS },
  ],
  GOLD_TALISMAN: [
    { trait_type: CLASS, value: GOLD },
    { trait_type: FAMILY, value: TALISMANS },
    { trait_type: "15/15", value: NAMES.GOLD_TALISMAN },
  ],
  TRIUMPH: [
    { trait_type: CLASS, value: NAMES.TRIUMPH },
    { trait_type: FAMILY, value: TULIPS },
  ],
  TRIUMPH_ALPHA: [
    { trait_type: CLASS, value: NAMES.TRIUMPH },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ALPHA },
  ],
  TRIUMPH_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.TRIUMPH },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED },
  ],
  FLOWER_ONE_ALPHA_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_ONE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED_ALPHA },
  ],
  FLOWER_TWO_ALPHA_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_TWO },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED_ALPHA },
  ],
  FLOWER_THREE_ALPHA_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_THREE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED_ALPHA },
  ],
  FLOWER_FOUR_ALPHA_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_FOUR },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED_ALPHA },
  ],
  FLOWER_FIVE_ALPHA_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.FLOWER_FIVE },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED_ALPHA },
  ],
  TRIUMPH_ALPHA_ECLIPSED: [
    { trait_type: CLASS, value: NAMES.TRIUMPH },
    { trait_type: FAMILY, value: TULIPS },
    { trait_type: EVOLUTION, value: ECLIPSED_ALPHA },
  ],
  PLATINUM_BOUQUET: [
    { trait_type: CLASS, value: PLATINUM },
    { trait_type: FAMILY, value: BOUQUETS },
  ],
  STONE_TALISMAN: [
    { trait_type: CLASS, value: "Stone" },
    { trait_type: FAMILY, value: TALISMANS },
  ],
  PLATINUM_TALISMAN: [
    { trait_type: CLASS, value: PLATINUM },
    { trait_type: FAMILY, value: TALISMANS },
    { trait_type: "10/10", value: NAMES.PLATINUM_TALISMAN },
  ],
  SPIRIT_TALISMAN: [
    { trait_type: CLASS, value: "Spirit" },
    { trait_type: FAMILY, value: TALISMANS },
    { trait_type: "1/1", value: NAMES.SPIRIT_TALISMAN },
  ],
};
