import React, { Component } from "react";
import "./App.css";
import { Button } from "react-bootstrap";

import moonlightButton from "../resources/moonlightButton.mp4";
const SEED_MINT_END_DATE = 1655218800000; // 8am on the 14th
const BETTA_MINT_END_DATE = 1655276400000; // Midnight On the 14th/15th
const MOONLIGHT_MINT_END_DATE = 1655452800000; // // 1 AM on the 17th

type Props = {|
  revealOneHappened: boolean,
  revealThreeHappened: boolean,
  revealFourHappened: boolean,
  revealFourHappened: boolean,
  numMemories: number,
  numRebirth: number,
  isFlowerMintActive: boolean,
  isMoonlightMintActive: boolean,
  isBettaMintActive: boolean,
  numToMint: number,
  onDecrementMintNumber: () => void,
  onIncrementMintNumber: () => void,
  onMintClicked: () => Promise<void>,
  height: number,
  width: number,
|};

class MintScreen extends Component<Props> {
  render() {
    const buttonHeight =
      this.props.height < 550
        ? ((this.props.height * 0.8) / 400) * 200
        : this.props.width < 500
        ? ((this.props.width * 0.8) / 400) * 200
        : 200;

    const now = new Date().getTime();
    let distance = SEED_MINT_END_DATE - now;
    if (this.props.isMoonlightMintActive) {
      distance = MOONLIGHT_MINT_END_DATE - now;
    }
    if (this.props.isMoonlightMintActive) {
      distance = BETTA_MINT_END_DATE - now;
    }
    const isMintActive =
      this.props.isFlowerMintActive ||
      this.props.isMoonlightMintActive ||
      this.props.isBettaMintActive;
    const bottomInfoShowing = this.props.height > 420;
    const infoShowing = this.props.height >= 560;
    const middleInfoShowing = this.props.height >= 660;
    const verticalMarginScale =
      this.props.height < 968 ? this.props.height / 968 : 1;
    const fontScale = this.props.width < 405 ? this.props.width / 405 : 1;

    // Time calculations for days, hours, minutes and seconds
    // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    let hoursText = hours.toString();
    let minutesText = minutes.toString();
    let secondsText = seconds.toString();
    if (hours < 10) {
      hoursText = "0" + hoursText;
    }
    if (minutes < 10) {
      minutesText = "0" + minutesText;
    }
    if (seconds < 10) {
      secondsText = "0" + secondsText;
    }

    const memoriesString = this.props.numMemories === 1 ? "Memory" : "Memories";

    let incrementString = this.props.numToMint > 1 ? "Seeds" : "Seed";
    let title = "⚘";
    let subtitle =
      "For each Memory token (M) held, up to 5 * M  seeds are available at the Collector's Rate";
    let subtitleTwo =
      "Collectors who mint 5 seeds will recieve 5 additional seeds at no cost";
    let subtitleThree =
      "Additional seeds can be minted afterwards for Base Rate";
    let baseRate = "Base Rate: Ξ0.25/seed";
    let collectorsRate = "Collector's Rate: Ξ0.025/seed";
    if (this.props.isBettaMintActive) {
      this.props.numToMint > 1
        ? (incrementString = "Bettas")
        : (incrementString = "Betta");
      title = "SUMMON THE ALPHA";
      subtitle = "Ξ0.035/Betta";
      subtitleTwo = "";
      subtitleThree = "";
    }
    if (this.props.isMoonlightMintActive) {
      incrementString = "Moonlight";
      title = "LUNAR ECLIPSE";
      subtitle = "Ξ0.035/Moonlight";
      subtitleTwo = "";
      subtitleThree = "";
    }

    const numMemories =
      this.props.numMemories != null ? this.props.numMemories : 0;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: this.props.height,
          width: "100%",
        }}
        class="fader"
      >
        <div
          style={{
            height: buttonHeight,
            maxWidth: this.props.width < 500 ? this.props.width * 0.8 : 400,
            flex: 1,
            flexGrow: 1,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <video
            style={{
              aspectRatio: 230 / 500,
              maxWidth: this.props.width < 500 ? this.props.width * 0.8 : 400,
              flex: 1,
              flexGrow: 1,
              width: "100%",
            }}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={moonlightButton} type="video/mp4" />
          </video>
        </div>
        <Button
          onClick={this.props.onMintClicked}
          style={{
            opacity: 0.6,
            height: buttonHeight,
            maxWidth: this.props.width < 500 ? this.props.width * 0.8 : 400,
            flex: 1,
            flexGrow: 1,
            width: "100%",
            fontSize: this.props.width < 420 ? "3.2rem" : "4.8rem",
            position: "absolute",
          }}
          variant="outline-light"
        ></Button>
        {isMintActive && bottomInfoShowing && (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: 45,
              bottom: (this.props.height - 74) / 2 - buttonHeight / 2 - 30,
              width: this.props.width,
              position: "absolute",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                opacity: 0.6,
                fontSize: 30,
                left: this.props.width / 2 - 92,
                position: "absolute",
              }}
            >
              {hoursText + ":" + minutesText + ":" + secondsText}
            </div>
          </div>
        )}
        {isMintActive && infoShowing && (
          <div
            style={{
              color: "#dadada",
              position: "absolute",
              top:
                (this.props.isFlowerMintActive ? 60 : 160) *
                verticalMarginScale,
              fontSize: (this.props.isFlowerMintActive ? 30 : 18) * fontScale,
              marginRight: 30,
              marginLeft: 30,
              textAlign: "center",
            }}
          >
            {title}
          </div>
        )}
        {isMintActive && infoShowing && (
          <div
            style={{
              color: "#dadada",
              position: "absolute",
              top:
                (this.props.isFlowerMintActive ? 130 : 230) *
                verticalMarginScale,
              fontSize: 12 * fontScale,
              textAlign: "center",
              marginRight: 30,
              marginLeft: 30,
            }}
          >
            {subtitle}
          </div>
        )}
        {isMintActive && infoShowing && (
          <div
            style={{
              color: "#dadada",
              position: "absolute",
              top:
                (this.props.isFlowerMintActive ? 180 : 280) *
                verticalMarginScale,
              fontSize: 12 * fontScale,
              textAlign: "center",
              marginRight: 30,
              marginLeft: 30,
            }}
          >
            {subtitleTwo}
          </div>
        )}
        {this.props.isFlowerMintActive && middleInfoShowing && (
          <div
            style={{
              color: "#dadada",
              position: "absolute",
              top: 300 * (verticalMarginScale * verticalMarginScale),
              fontSize: 16 * fontScale,
              textAlign: "center",
              marginRight: 30,
              marginLeft: 30,
            }}
          >
            {baseRate}
          </div>
        )}
        {this.props.isFlowerMintActive && middleInfoShowing && (
          <div
            style={{
              color: "#dadada",
              position: "absolute",
              top: 330 * verticalMarginScale * verticalMarginScale,
              fontSize: 16 * fontScale,
              textAlign: "center",
              marginRight: 30,
              marginLeft: 30,
            }}
          >
            {collectorsRate}
          </div>
        )}

        {isMintActive && (
          <div
            style={{
              display: "flex",
              position: "absolute",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              bottom:
                (this.props.isFlowerMintActive ? 220 : 160) *
                (verticalMarginScale * verticalMarginScale),
              textAlign: "center",
              width: 300 * fontScale,
            }}
          >
            <div
              onClick={this.props.onDecrementMintNumber}
              style={{
                cursor: "pointer",
                color: "#dadada",
                fontSize: 16 * fontScale,
                textAlign: "center",
                width: 30,
                height: 30,
              }}
            >
              {"<"}
            </div>
            <div
              style={{
                color: "#dadada",
                fontSize: 20 * fontScale,
                textAlign: "center",
              }}
            >
              {this.props.numToMint + " " + incrementString}
            </div>
            <div
              onClick={this.props.onIncrementMintNumber}
              style={{
                cursor: "pointer",
                color: "#dadada",
                fontSize: 16 * fontScale,
                textAlign: "center",
                width: 30,
                height: 30,
              }}
            >
              {">"}
            </div>
          </div>
        )}
        {this.props.isFlowerMintActive && bottomInfoShowing && (
          <div
            style={{
              color: "#dadada",
              position: "absolute",
              bottom: 160 * (verticalMarginScale * verticalMarginScale),
              fontSize: 12 * fontScale,
              textAlign: "center",
              marginRight: 30,
              marginLeft: 30,
            }}
          >
            {"You Have " +
              numMemories +
              " " +
              memoriesString +
              " And Have Redeemed " +
              this.props.numRebirth +
              "/" +
              numMemories * 5 +
              " Collector's Rate Seeds"}
          </div>
        )}
        {this.props.isFlowerMintActive && bottomInfoShowing && (
          <div
            style={{
              color: "#dadada",
              position: "absolute",
              bottom: 110 * (verticalMarginScale * verticalMarginScale),
              fontSize: 12 * fontScale,
              textAlign: "center",
              marginRight: 30,
              marginLeft: 30,
            }}
          >
            {subtitleThree}
          </div>
        )}
      </div>
    );
  }
}
export default MintScreen;
