import React, { Component } from "react";
import "./App.css";
import { Button, ButtonGroup } from "react-bootstrap";
import BouquetScreen from "./BouquetScreen";
import EvolutionScreen from "./EvolutionScreen";
import LockedDateScreen from "./LockedDateScreen";

type Props = {|
  revealOneHappened: boolean,
  revealTwoHappened: boolean,
  revealThreeHappened: boolean,
  revealFourHappened: boolean,
  hasUnusedGoldBouquet: boolean,
  height: number,
  width: number,
  bouquetItemTitle: String,
  onBouquetItemSelected: (eventKey: string) => void,
  evolutionItemOneTitle: String,
  evolutionItemTwoTitle: String,
  onEvolutionItemOneSelected: (eventKey: string) => void,
  onEvolutionItemTwoSelected: (eventKey: string) => void,
  onClickLabTabEvolution: () => void,
  onClickLabTabFestival: () => void,
  onArrangeSelected: () => void,
  onFuckSelected: () => void,
  onSacrificeSelected: () => void,
  tokenCounts: Object,
|};

const LAB_SUB_TAB = {
  EVOLUTION: 0,
  BOUQUET: 1,
};

class LabScreen extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeLabTab: LAB_SUB_TAB.EVOLUTION,
    };
  }
  render() {
    return <LockedDateScreen lockedDate={"COMING SOON"} />;
    const TOGGLE_FONT_SIZE =
      this.props.width < 450 ? (16 / 450) * this.props.width : 16;

    const EVOLUTION_CONTENT = (
      <EvolutionScreen
        revealOneHappened={this.props.revealOneHappened}
        revealTwoHappened={this.props.revealTwoHappened}
        revealThreeHappened={this.props.revealThreeHappened}
        revealFourHappened={this.props.revealFourHappened}
        hasUnusedGoldBouquet={this.props.hasUnusedGoldBouquet}
        itemOneTitle={this.props.evolutionItemOneTitle}
        itemTwoTitle={this.props.evolutionItemTwoTitle}
        onItemOneSelected={this.props.onEvolutionItemOneSelected}
        onItemTwoSelected={this.props.onEvolutionItemTwoSelected}
        onFuckSelected={this.props.onFuckSelected}
        tokenCounts={this.props.tokenCounts}
        height={this.props.height}
        width={this.props.width}
      />
    );

    const BOUQUET_CONTENT = (
      <BouquetScreen
        revealTwoHappened={this.props.revealTwoHappened}
        revealThreeHappened={this.props.revealThreeHappened}
        revealFourHappened={this.props.revealFourHappened}
        bouquetItemTitle={this.props.bouquetItemTitle}
        height={this.props.height}
        onArrangeSelected={this.props.onArrangeSelected}
        onBouquetItemSelected={this.props.onBouquetItemSelected}
        tokenCounts={this.props.tokenCounts}
        width={this.props.width}
      />
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
          height: this.props.height,
          paddingBottom: "10%",
          paddingTop: 100,
          width: "100%",
        }}
        class="fader"
      >
        <ButtonGroup aria-label="Basic example" style={{ opacity: 0.7 }}>
          <Button
            style={{ fontSize: TOGGLE_FONT_SIZE }}
            onClick={() => {
              this.setState({ activeLabTab: LAB_SUB_TAB.EVOLUTION });
              this.props.onClickLabTabEvolution();
            }}
            variant="outline-light"
            active={this.state.activeLabTab === LAB_SUB_TAB.EVOLUTION}
          >
            Evolution
          </Button>
          <Button
            style={{ fontSize: TOGGLE_FONT_SIZE }}
            onClick={() => {
              this.setState({ activeLabTab: LAB_SUB_TAB.BOUQUET });
              this.props.onClickLabTabFestival();
            }}
            variant="outline-light"
            active={this.state.activeLabTab === LAB_SUB_TAB.BOUQUET}
          >
            Festival
          </Button>
        </ButtonGroup>
        {this.state.activeLabTab === LAB_SUB_TAB.EVOLUTION
          ? EVOLUTION_CONTENT
          : BOUQUET_CONTENT}
      </div>
    );
  }
}
export default LabScreen;
