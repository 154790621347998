import React, { Component } from "react";
import "./App.css";
import { Dropdown } from "react-bootstrap";

type Props = {|
  onSelect: (eventKey: string) => void,
  itemStrings: Array<String>,
  itemStyle?: StyleSheet,
  itemTitle: String,
  toggleStyle?: StyleSheet,
|};

class WFModal extends Component<Props> {
  render() {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          style={this.props.toggleStyle}
        >
          {this.props.itemTitle}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {this.props.itemStrings.map((item) => {
            return (
              <Dropdown.Item
                style={this.props.itemStyle}
                onSelect={this.props.onSelect}
                eventKey={item}
                href="#"
              >
                {item}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
export default WFModal;
