import React, { Component } from "react";
import "./App.css";

type Props = {|
  lockedDate: String,
|};

class LockedDateScreen extends Component<Props> {
  render() {
    return (
      <div
        class="fader"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          color: "red",
          opacity: 0.6,
          textAlign: "center",
          fontSize: 24,
        }}
      >
        {this.props.lockedDate}
      </div>
    );
  }
}
export default LockedDateScreen;
